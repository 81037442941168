import {useCallback, useEffect, useRef} from "react";
import _ from "lodash";

export function useDebounced(fn, delay, deps) {
    const debounced = useRef(null);
    useEffect(() => {
        debounced.current = _.debounce(fn, delay);
        return () => {
            debounced.current.cancel();
        }
    }, deps);
    return useCallback((...savedArgs) => debounced.current.apply(null, savedArgs), []);
}
