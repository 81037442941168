import React, {useContext} from "react";
import MobileDetect from "mobile-detect";

export default class DeviceInfo {
    constructor(fromWindow = window) {
        this.window = fromWindow;
        this.mobileDetect = new MobileDetect(this.window.navigator.userAgent);
    }

    get isIPhone() {
        return this.mobileDetect.is("iPhone");
    }

    get isIPad() {
        return this.mobileDetect.is("iPad");
    }

    get isAndroid() {
        return this.mobileDetect.is("AndroidOS");
    }

    get isAndroidStandalone() {
        return this.window.matchMedia && this.window.matchMedia('(display-mode: standalone)').matches;
    }

    get isMobilePhone() {
        return this.mobileDetect.phone() !== null;
    }

    get isIOSStandalone() {
        return this.window.navigator.standalone === true;
    }

    get isUsingTimMobile() {
        return this.window.self === this.window.top; // not in iframe, top level is us.
    }
}

export const DeviceInfoContext = React.createContext(new DeviceInfo());

export function useDeviceInfo() {
    return useContext(DeviceInfoContext);
}
