import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import validateUrl from "../../common/validateUrl";

const WebLink = (props) => (
    <div className={classNames("pure-control-group", "web-link", "x-web-link", validateUrl(props.value), props.requiredClass)}>
        <label htmlFor="web-link-input">Web Link</label>
        <input id="web-link-input"
               className="x-web-link-input idea-attribute__input"
               type="text"
               value={props.value}
               onChange={(event) => props.onChange(event.currentTarget.value)}
               style={{width: 265}} />
    </div>
);
WebLink.propTypes = {
    onChange: PropTypes.func.isRequired,
    requiredClass: PropTypes.string.isRequired,
    value: PropTypes.string
};

export default WebLink;
