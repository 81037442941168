import React, {useCallback, useState} from "react";
import PropTypes from "prop-types";
import Portfolio from "./Portfolio";
import * as ourPropTypes from "./propTypes";

const PortfolioList = ({classifiedPortfolios = [], heading = "", rightHeading = "", identifier, onToggleAllPortfolios, onCheckboxChange, incompatibleErrorsOnly = false}) => {
    const [checked, setChecked] = useState(true);
    const handleToggleAllPortfolios = useCallback(event => {
        setChecked(event.target.checked);
        onToggleAllPortfolios({ checked: event.target.checked });
    }, [onToggleAllPortfolios]);

    const checkbox = onToggleAllPortfolios ?
        <input type="checkbox"
               checked={checked ? "checked" : ""}
               onChange={handleToggleAllPortfolios}
               value="toggleAllPortfolios"
               style={{marginRight: "0.5em"}}
        />
        : "";

    const showHeader = classifiedPortfolios.length > 0;
    const tableHeader = showHeader ?
        <thead style={{ backgroundColor: "transparent" }}>
        <tr>
            <th>{checkbox}</th>
            <th><span className="portfolio-list__heading x-portfolio-header">{heading}</span></th>
            <th/>
            <th style={{ textAlign: "right" }} className="portfolio-list__investment-amount-header">
                {rightHeading}
            </th>
        </tr>
        </thead>
        : null;

    const mobileHeading = showHeader ?
        <h2 className="section-heading portfolio-list__mobile-header x-portfolio-header-mobile" data-identifier={identifier}>{heading}</h2>
        : null;

    const portfolioContent = classifiedPortfolios.map(({ portfolio, selected, classification, errors }) => (
        <Portfolio
            key={portfolio.id}
            checked={selected && classification === "valid"}
            portfolioValidationErrors={ errors.filter(error => !(incompatibleErrorsOnly && error.fixable)).map(error => error.message) }
            investmentAmount={portfolio.investmentAmount}
            onCheckboxChange={onCheckboxChange}
            {...portfolio}/>
    ));

    return (
        <div>
            { mobileHeading }
            <div className="portfolio-list-padding-fix">
                <table style={{ width: "100%", border: "0px" }} className="pure-table pure-table-horizontal portfolio-list" data-identifier={identifier}>
                    { tableHeader }
                    { portfolioContent }
                </table>
            </div>
        </div>
    );
};

export default PortfolioList;

PortfolioList.propTypes = {
    heading: PropTypes.string.isRequired,
    rightHeading: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    incompatibleErrorsOnly: PropTypes.bool,
    classifiedPortfolios: PropTypes.arrayOf(ourPropTypes.classifiedPortfolio).isRequired,
    onCheckboxChange: PropTypes.func,
    onToggleAllPortfolios: PropTypes.func
};
