import * as consts from "../../common/constants.js";

export default function(timeHorizonIndex) {
    if (timeHorizonIndex === consts.TIME_HORIZON_2D) {
        return "< 2 days";
    }
    if (timeHorizonIndex === consts.TIME_HORIZON_1W) {
        return "< 1 week";
    }
    if (timeHorizonIndex === consts.TIME_HORIZON_1M) {
        return "< 1 month";
    }
    if (timeHorizonIndex === consts.TIME_HORIZON_3M) {
        return "< 3 months";
    }
    if (timeHorizonIndex === consts.TIME_HORIZON_6M) {
        return "< 6 months";
    }
    if (timeHorizonIndex === consts.TIME_HORIZON_1YPLUS) {
        return "> 12 months";
    }
    return null;
}
