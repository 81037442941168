import htmlparser from "htmlparser";
import * as R from "ramda";

function filterAndFlattenChildrenFor(f, id) {
    return R.flatten(f.filter(entry => entry.attribs && entry.attribs.id === id).map(entry => entry.children));
}

function toNumber(value) {
    return parseFloat(value.replace(/,/g, ""));
}

export default function (htmlResponse) {
    const handler = new htmlparser.DefaultHandler((error, dom) => {
        if (error) {
            console.log("Error: " + error);
        } else {
            // do nothing special
        }
    });

    const parser = new htmlparser.Parser(handler);
    parser.parseComplete(htmlResponse);

    let f = filterAndFlattenChildrenFor(handler.dom, "DATASETIDEAGROUPRULES_wrapper");
    f = filterAndFlattenChildrenFor(f, "DATASETIDEAGROUPRULES");
    f = filterAndFlattenChildrenFor(f, "DATASETIDEAGROUPRULES-data");
    f = filterAndFlattenChildrenFor(f, "DATASETIDEAGROUPRULES_main");

    f = f.filter(entry => entry.attribs && entry.attribs.id && entry.attribs.id.startsWith("DATASETIDEAGROUPRULES"));

    const numberOfPortfolioRules = f.length;

    f = R.flatten(f.map(entry => entry.children));

    f = f.filter(entry => entry.name === "td");

    f = R.flatten(f.map(entry => entry.children));

    const portfolioRules = [];
    for (let i = 0; i < numberOfPortfolioRules; i++) {
        const hardLimit = f.find(entry => entry.attribs.id === "DATASETIDEAGROUPRULES_HARD_LIMIT_" + i + "_DISPLAY").children[0].data.trim();
        const currentValue = f.find(entry => entry.attribs.id === "DATASETIDEAGROUPRULES_CURRENT_VALUE_" + i + "_DISPLAY").children[0].data.trim();

        portfolioRules.push({
            name: f.find(entry => entry.attribs.id === "DATASETIDEAGROUPRULES_RULE_NAME_" + i + "_DISPLAY").children[0].data.trim(),
            hardLimit: hardLimit.length !== 0 ? toNumber(hardLimit) : null,
            currentValue: currentValue !== "N/A" ? toNumber(currentValue) : null
        });
    }

    return portfolioRules;
}
