import React from "react";
import PropTypes from "prop-types";

function messagesOfType(type, messages) {
    return messages.map((message, index) => (
        <div className={"message--" + type + " x-" + type + "-msg"} key={type + "-" + index}>
            {message}
        </div>
    ));
}

const MessageRenderer = ({position, success = [], errors = [], warnings = []}) => (
    <div className="message" style={{ position: position }}>
        { messagesOfType("success", success) }
        { messagesOfType("error", errors) }
        { messagesOfType("warning", warnings) }
    </div>
);

MessageRenderer.propTypes = {
    success: PropTypes.arrayOf(PropTypes.string),
    errors: PropTypes.arrayOf(PropTypes.string),
    warnings: PropTypes.arrayOf(PropTypes.string)
};

export default MessageRenderer;
