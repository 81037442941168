import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import IdeaAttributes from "./IdeaAttributes.js";
import Portfolios from "./Portfolios.js";
import * as distributionActions from "./../actions/distributionActions.js";
import {fetchPortfoliosFromTIM} from "./../actions/fetchPortfoliosFromTIM.js";
import {classifyPortfolios, portfoliosWithPricingMethods} from "../portfolioSelectors.js";
import validatePortfolioRulesUsingTIM from "../actions/validatePortfolioRulesUsingTIM.js";
import {isRestricted} from "./RestrictionCodes";
import {useDebounced} from "../../hooks/Debounced";
import validateUrl from "../../common/validateUrl";

function investmentAmountErrors(portfoliosWithAmounts, ideaAttributes) {
    return portfoliosWithAmounts
        .filter(portfolio => portfolio.investmentAmount === null)
        .map(portfolio => {
            return {
                forID: portfolio.id,
                message: "No valid investment amount for this portfolio",
                fixable: false
            };
        });
}

const IdeaDistributionComponent = ({ fetchPortfolios = fetchPortfoliosFromTIM, validatePortfolioRules = validatePortfolioRulesUsingTIM }) => {
    const {distribution: { ideaAttributes, ideaAttachments, portfolios, portfoliosRequirements, portfolioRuleValidationResult: rawPortfolioRuleValidationErrors,
                            portfoliosExcludedFromIdea, isDistributingIdea, validationsInFlight, submissionStatus, supportingInformation },
           showConflictOfInterest, restrictedStocks, restrictedStocksAreStale, hideTargetPrice, blockRestrictedStocks} =
        useSelector(({distribution, showConflictOfInterest, restrictedStocks, restrictedStocksAreStale, hideTargetPrice, blockRestrictedStocks}) => ({
                     distribution, showConflictOfInterest, restrictedStocks, restrictedStocksAreStale, hideTargetPrice, blockRestrictedStocks }));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(distributionActions.loadPortfolios(fetchPortfolios));
    }, []);
    const lazyValidatePortfolios = useDebounced(() => {
        dispatch(distributionActions.validateIdeaAttributesAgainstPortfolioRules(validatePortfolioRules));
    }, 300, []);

    const handleIdeaAttributesChange = useCallback(changedAttribute => {
        dispatch(distributionActions.changeIdeaAttributes(changedAttribute));
        lazyValidatePortfolios();
    }, []);

    const handleStockSymbolChange = useCallback((stockPublicId, stockSymbol, companyName) => {
        if (stockSymbol !== ideaAttributes.ticker) {
            dispatch(distributionActions.changeStockSymbol(stockPublicId, stockSymbol, companyName));
            dispatch(distributionActions.validateIdeaAttributesAgainstPortfolioRules(validatePortfolioRules));
        }
    }, []);

    const portfoliosWithAmounts = portfoliosWithPricingMethods({
        portfolios,
        portfoliosRequirements
    }).map(portfolio => {
        const indicativeInvestment = portfolio.indicativeInvestments[ideaAttributes.investmentSize];
        const investment = rawPortfolioRuleValidationErrors.investments.find(r => r.id === portfolio.id);
        return { ...portfolio, investmentAmount: investment ? investment.amount : indicativeInvestment };
    });

    const portfolioRuleValidationErrors = investmentAmountErrors(portfoliosWithAmounts, ideaAttributes).concat(rawPortfolioRuleValidationErrors.failures);
    const classifiedPortfolios = classifyPortfolios(portfoliosWithAmounts, portfolioRuleValidationErrors, portfoliosExcludedFromIdea);

    const restrictedStockMessage =
        blockRestrictedStocks && isRestricted(restrictedStocks, ideaAttributes.stockPublicId) ? ideaAttributes.ticker + " is a restricted stock" : null;

    const conflictOfInterestMessage =
        showConflictOfInterest && ideaAttributes.ticker && !ideaAttributes.conflictOfInterest
            ? "You must specify whether there is a conflict of interest or not"
            : showConflictOfInterest && ideaAttributes.ticker && ideaAttributes.conflictOfInterest.exists && !ideaAttributes.conflictOfInterest.description
            ? "You must fill in the description of the conflict of interest"
            : null;

    return (
        <form className="pure-form pure-form-aligned x-idea-distribution send-idea">
            <div className="pure-1 pure-u-lg-1-2 send-idea__idea-attributes">
                <IdeaAttributes
                    {...ideaAttributes}
                    ideaAttachments={ideaAttachments}
                    supportingInformation={supportingInformation}
                    dispatch={dispatch}
                    classifiedPortfolios={classifiedPortfolios}
                    restrictedStocks={restrictedStocks}
                    hideTargetPrice={hideTargetPrice}
                    showConflictOfInterest={showConflictOfInterest}
                    onIdeaAttributesChange={handleIdeaAttributesChange}
                    onStockSymbolChange={handleStockSymbolChange}/>
            </div>
            <Portfolios
                dispatch={dispatch}
                missingOrInvalidDetails={!(ideaAttributes.stockPublicId && ideaAttributes.direction && validateUrl(ideaAttributes.webLink) === "" && (ideaAttachments.valueSeq().filter(it => it.uploadState !== "UPLOADED").isEmpty()))}
                hardBlock={restrictedStockMessage}
                conflictOfInterestMessage={conflictOfInterestMessage}
                classifiedPortfolios={classifiedPortfolios}
                isDistributingIdea={isDistributingIdea}
                validationsInFlight={validationsInFlight}
                submissionStatus={submissionStatus}
                restrictedStocksLoadingOrStale={!restrictedStocks || restrictedStocksAreStale}
            />
        </form>
    )
};

export default IdeaDistributionComponent;
