import React, {useCallback, useRef} from "react";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";

import {observeAjax} from "../../authenticationSafeAjax";
import TIMMessagesFrom from "../../TIMMessagesFrom.js";
import MessageRenderer from "../../common/components/MessageRenderer";

import {changePasswordErrors, passwordChanged} from "../actions/authenticationActions";
import {useSingleSubscriptionHolder} from "../../hooks/SubscriptionHolder";

const ChangePassword = ({}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const errors = useSelector(state => state.changePasswordErrors);
    const passwordElt = useRef(null);
    const confirmElt = useRef(null);
    const registerPasswordChange = useSingleSubscriptionHolder();
    const handleSubmit = useCallback(event => {
        event.preventDefault();

        registerPasswordChange(observeAjax({
            url: "/TradeIdeasMonitor/monitor",
            method: "POST",
            contentType: "application/x-www-form-urlencoded",
            body: "page=com.youdevise.ids.controller.ChoosePermanentPasswordController" +
                "&action=update" +
                "&newpassword=" + encodeURIComponent(passwordElt.current.value) +
                "&newpasswordconfirm=" + encodeURIComponent(confirmElt.current.value)
        }).subscribe(({data: text}) => {
            const messages = TIMMessagesFrom(text);

            if (text.indexOf("Successfully changed password") !== -1) {
                history.push("/home");
                dispatch(passwordChanged());
            } else if (messages.errors.length !== 0) {
                dispatch(changePasswordErrors(messages.errors));
            }
        }));
    }, [history, dispatch]);

    return (
        <div>
            <MessageRenderer errors={errors}/>
            <form className="pure-form pure-form-aligned" onSubmit={handleSubmit} style={{padding: ".5em"}}>
                <div id="logo"/>
                <fieldset>
                    <legend>Please choose a new password and enter it twice below</legend>

                    <div className="pure-control-group">
                        <label htmlFor="newpassword">Your new password</label>
                        <input type="password" id="newpassword" ref={ passwordElt }/>
                    </div>

                    <div className="pure-control-group">
                        <label htmlFor="newpasswordconfirm">Confirm password</label>
                        <input type="password" id="newpasswordconfirm" ref={ confirmElt } />
                    </div>

                    <div className="pure-controls">
                        <button type="submit" className="pure-button pure-button-primary">Change password</button>
                    </div>
                </fieldset>
            </form>
        </div>
    );
};

export default ChangePassword;
