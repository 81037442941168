import React from "react";
import BlueInfoTip from "./BlueInfoTip.js";
import ConflictOfInterest from "./ConflictOfInterest";

import ButtonGroup from "./../../common/components/ButtonGroup.js";
import StockSymbol from "./../../common/components/StockSymbol.js";
import Direction from "../../common/components/Direction";
import Commentary from "../../common/components/Commentary";
import TargetPrice from "../../common/components/TargetPrice";
import TimeHorizon from "../../common/components/TimeHorizon";
import validateFloat from "./../../common/components/validateFloat";
import {
    commentaryIsRequired,
    minCommentaryRuleIsViolated,
    targetPriceIsRequired,
    timeHorizonIsRequired
} from "./inputFieldIsRequired.js";
import {INVESTMENT_SIZE_LARGE, INVESTMENT_SIZE_MEDIUM, INVESTMENT_SIZE_SMALL} from "../constants.js";

import {logSizeTipClose, logSizeTipOpen} from "../../logEvent.js";
import {RestrictionCodes} from "./RestrictionCodes";
import SupportingInformation from "./SupportingInformation";

export const CommentaryDispatch = ({commentary, requiredClass, onCommentaryChange}) => {
    return <Commentary label="Commentary" key="commentary" id="commentary"
                           value={commentary}
                           requiredClass={requiredClass}
                           onChange={onCommentaryChange}/>;
};

function commentaryValidationCssClasses(props, compatiblePortfolios) {
    const {ticker, commentary} = props;

    const commentaryRuleIsViolated = minCommentaryRuleIsViolated(compatiblePortfolios);

    const cssClasses = [];
    if (ticker) {
        // portfolio attribute says commentary is required?
        if (commentaryIsRequired(compatiblePortfolios)) {
            if (!commentary) {
                cssClasses.push("description-required");
            }
        }
        // portfolio rule enforcing min number of words?
        if (commentaryRuleIsViolated) {
            cssClasses.push("commentary-moreWordsRequired");
        }
    }

    if (cssClasses.length === 0) {
        // no validation errors
        cssClasses.push("idea-attribute");
    } else {
        cssClasses.push("idea-attribute--required");
    }

    return cssClasses.join(" ");
}

const IdeaAttributes = (props) => {
    const {onIdeaAttributesChange} = props;

    const requiredFor = isRequired => (props.ticker && isRequired) ? "idea-attribute--required" : "idea-attribute";

    const compatiblePortfolios = props.classifiedPortfolios.filter(cp => cp.classification !== "incompatible");

    const requiredClassForDirection = requiredFor(!props.direction);
    const requiredClassForTargetPrice = requiredFor(!props.targetPrice && targetPriceIsRequired(compatiblePortfolios));
    const requiredClassForTimeHorizon = requiredFor(!props.timeHorizon && timeHorizonIsRequired(compatiblePortfolios));

    const requiredClassForCommentary = commentaryValidationCssClasses(props, compatiblePortfolios);

    return (
        <fieldset>
            <StockSymbol onStockSymbolChange={props.onStockSymbolChange}
                         restrictedStocks={props.restrictedStocks}/>
            <RestrictionCodes restrictedStocks={props.restrictedStocks}
                              stockPublicId={props.stockPublicId}/>

            <Direction value={props.direction}
                       onChange={direction => onIdeaAttributesChange({direction})}
                       requiredClass={requiredClassForDirection}/>

            <div className="pure-control-group">
                <label>Size</label>
                <ButtonGroup className="x-investment-size-group"
                             onChange={investmentSize => onIdeaAttributesChange({investmentSize})}
                             value={props.investmentSize}
                             buttons={[
                                 {
                                     label: "Small",
                                     value: INVESTMENT_SIZE_SMALL,
                                     className: "idea-attribute__input"
                                 },
                                 {
                                     label: "Medium",
                                     value: INVESTMENT_SIZE_MEDIUM,
                                     className: "idea-attribute__input"
                                 },
                                 {
                                     label: "Large",
                                     value: INVESTMENT_SIZE_LARGE,
                                     className: "idea-attribute__input"
                                 }
                             ]}/>
                <BlueInfoTip id="Investment Help"
                             className="investment-size__investment-help"
                             onOpen={logSizeTipOpen}
                             onClose={logSizeTipClose}>
                    <div style={{
                        lineHeight: "150%",
                        fontSize: "80%",
                        margin: "0.3em 0"
                    }}>
                        <strong>Size</strong> calculates the
                        best <strong>Investment Amount</strong> for
                        each Portfolio according to the Portfolio Rules
                        and the ADV. In absence of other constraints the amounts are:<br/>
                        <ul>
                            <li>Large: 5m USD</li>
                            <li>Medium: 2m USD</li>
                            <li>Small: 1m USD</li>
                        </ul>
                    </div>
                </BlueInfoTip>
            </div>
            {
                props.hideTargetPrice ? undefined
                    : <TargetPrice value={props.targetPrice}
                                   onChange={targetPrice => onIdeaAttributesChange({targetPrice})}
                                   requiredClass={requiredClassForTargetPrice}/>
            }
            <div className={"pure-control-group stop-loss " + validateFloat(props.stopLossAlert)}>
                <label htmlFor="stopLoss">Stop Loss Alert</label>
                <input id="stopLoss" className="x-stop-loss-input idea-attribute__input"
                       type="number"
                       value={props.stopLossAlert}
                       onChange={(event) => {
                           const stopLossAlert = event.target.value;
                           return onIdeaAttributesChange({stopLossAlert});
                       }}
                       style={{width: "4em"}}/> %
            </div>

            <TimeHorizon value={props.timeHorizon}
                         onChange={timeHorizon => onIdeaAttributesChange({timeHorizon})}
                         requiredClass={requiredClassForTimeHorizon}/>

            <CommentaryDispatch
                commentary={props.commentary}
                requiredClass={requiredClassForCommentary}
                onCommentaryChange={commentary => onIdeaAttributesChange({commentary})}
            />

            <SupportingInformation visible={props.supportingInformation} webLink={props.webLink} ideaAttachments={props.ideaAttachments} dispatch={props.dispatch}/>

            {
                props.showConflictOfInterest
                    ? <ConflictOfInterest conflictOfInterest={props.conflictOfInterest}
                                          onChange={conflictOfInterest => onIdeaAttributesChange({conflictOfInterest})}
                                          requiredClass={requiredFor(!props.conflictOfInterest)}
                                          descriptionRequiredClass={requiredFor(props.conflictOfInterest && props.conflictOfInterest.exists && props.conflictOfInterest.description === "")}/>
                    : undefined
            }
        </fieldset>
    );
};

export default IdeaAttributes;
