import {observeAjax} from "../../authenticationSafeAjax";

import TIMPortfolioRulesFrom from "./TIMPortfolioRulesFrom.js";
import TIMPortfolioRulesForInvestmentAlgo from "./TIMPortfolioRulesForInvestmentAlgo.js";
import parsePortfolioPricingMethodFrom from "./parsePortfolioPricingMethodFrom.js";

export default function (portfolioId) {
    return observeAjax({
        url: "/TradeIdeasMonitor/monitor?customAction=IdeaGroupRulesDisplay&selecteditem=" + portfolioId,
        method: 'GET',
        headers: {Accept: 'text/html'}
    }).map(({data}) => ({
        id: portfolioId,
        rules: TIMPortfolioRulesForInvestmentAlgo(TIMPortfolioRulesFrom(data)),
        pricingMethod: parsePortfolioPricingMethodFrom(data)
    }));
}
