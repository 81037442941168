import {useCallback, useEffect, useRef} from "react";

export function useSingleSubscriptionHolder(inputs = []) {
    const subscriptionRef = useRef(null);
    useEffect(() => {
        return () => {
            if (subscriptionRef.current)
                subscriptionRef.current.unsubscribe();
        };
    }, inputs);

    return useCallback(subscription => {
        if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
        }
        subscriptionRef.current = subscription;
    }, []);
}

export function useSubscriptionsHolder(inputs = []) {
    const subscriptionListRef = useRef([]);
    useEffect(() => {
        return () => {
            for (const subscription of subscriptionListRef.current) {
                subscription.unsubscribe();
            }
            subscriptionListRef.current.length = 0;
        }
    }, inputs);

    return useCallback(subscription => {
        subscriptionListRef.current.push(subscription);
    }, []);
}
