import htmlparser from "htmlparser";
import * as R from "ramda";
import {htmlDecode} from "./distribution/htmlEnDeCode.js";

export function searchClass(className, element) {
    if (element.attribs && element.attribs.class && R.contains(className, element.attribs.class.split(" "))) {
        return [element];
    }
    if (element.children) {
        return R.flatten(element.children.map(child => searchClass(className, child)));
    }
    return [];
}

export function textOf(node) {
    return R.flatten(node.children.filter(child => child.type === "tag")
        .map(child => child.children.map(child => child.data)))
        .join("");
}

export default function(htmlResponse) {
    const handler = new htmlparser.DefaultHandler(function(error, dom) {
        if (error) {
            console.log("Error: " + error);
        } else {
            // do nothing special
        }
    });

    const parser = new htmlparser.Parser(handler);
    parser.parseComplete(htmlResponse);

    const noscriptError = "Error: You have Javascript disabled.  This application requires the use of Javascript.  Please enable it.";
    const errors = R.flatten(handler.dom.map(entry => searchClass("Error", entry)))
        .map(errorNode => htmlDecode(textOf(errorNode)))
        .filter(message => !message.includes(noscriptError));

    const success = R.flatten(handler.dom.map(entry => searchClass("Success", entry)))
        .map(successNode => htmlDecode(textOf(successNode)));

    return { success, errors };
}
