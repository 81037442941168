export const ERROR_COLOR = "#D0021B";

export const DIRECTION_LONG = "LONG";
export const DIRECTION_SHORT = "SHORT";

export const TIME_HORIZON_2D = "TIME_HORIZON_2D";
export const TIME_HORIZON_1W = "TIME_HORIZON_1W";
export const TIME_HORIZON_1M = "TIME_HORIZON_1M";
export const TIME_HORIZON_3M = "TIME_HORIZON_3M";
export const TIME_HORIZON_6M = "TIME_HORIZON_6M";
export const TIME_HORIZON_1YPLUS = "TIME_HORIZON_1YPLUS";
