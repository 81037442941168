import React from "react";
import {ERROR_COLOR} from "../common/constants";

const RestrictedStock = () => (
    <span style={{
        backgroundColor: ERROR_COLOR,
        display: "inline-block",
        textAlign: "center",
        color: "white",
        padding: "2px 4px",
        borderRadius: "2px",
        fontSize: "60%",
        verticalAlign: "middle",
        fontFamily: "Arial",
        marginLeft: ".25em",
        marginRight: ".25em"
    }}>R</span>
);

export default RestrictedStock;
