import React, {useCallback, useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {authenticate as authenticateWithTIM} from "../actions/authenticationWithTIM.js";
import {logLogin} from "../../logEvent";
import AddToHomescreen from "../../mobile/install/AddToHomescreen";

const errorColor = "rgb(217, 83, 79)";

function accountsUrl() {
    if (typeof WEBUI_CONFIG === 'undefined') {
        return "no-server-provided-config-assuming-javascript-testing";
    }
    return WEBUI_CONFIG.accountsUrl;
}

function passwordResetLink() {
    return accountsUrl() + "#/request-password-reset?origin=TIM";
}

const SignIn = ({authenticate = authenticateWithTIM}) => {
    const location = useLocation();
    const history = useHistory();
    const [failedAuthentication, setFailedAuthentication] = useState(false);
    const [loginInProgress, setLoginInProgress] = useState(false);
    useEffect(() => {
        logLogin("User opened the login screen.");
    }, []);
    const usernameElt = useRef(null);
    const passwordElt = useRef(null);

    const nextPathname = location.state ? location.state.nextPathname : "/home";

    const handleSubmit = useCallback(event => {
        function onSuccess() {
            setLoginInProgress(false);
            history.push(nextPathname);
        }

        function onError() {
            setFailedAuthentication(true);
            setLoginInProgress(false);
        }

        function onPasswordReset() {
            history.push("/change-password");
        }

        event.preventDefault();

        setLoginInProgress(true);

        authenticate(usernameElt.current.value, passwordElt.current.value, onSuccess, onError, onPasswordReset);
    }, [nextPathname]);

    let errorMessage;
    let passwordInputStyle = {};

    if (failedAuthentication) {
        errorMessage = <div style={{color: errorColor}}>The username and password you entered don't match.</div>;
        passwordInputStyle.borderColor = errorColor;
    }

    return (
        <div style={{maxWidth: 500}}>
            <div className="pure-g" style={{padding: ".5em"}}>
                <form id="signInForm" className="pure-form pure-u-1" style={{fontSize: "120%"}} onSubmit={handleSubmit}>
                    <div id="logo"/>
                    <input ref={usernameElt} type="text" className="pure-u-1" placeholder="Username" autoComplete="off" autoCapitalize="off" autoCorrect="off" spellCheck={false} />
                    <input ref={passwordElt} type="password" className="pure-u-1" placeholder="Password" style={passwordInputStyle}/>
                    {errorMessage}
                    <button type="submit" className="pure-button pure-u-1 pure-button-primary" disabled={loginInProgress}>{loginInProgress ? "Signing in..." : "Sign in"}</button>
                </form>

                <div style={{marginTop: "2em"}} className="pure-u-1">
                    <a href={passwordResetLink()}>Can't log on?</a>
                </div>
            </div>
            <AddToHomescreen/>
        </div>
    );
};

export default SignIn
