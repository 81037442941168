import React from "react";
import PropTypes from "prop-types";
import PortfolioList from "./PortfolioList.js";
import MessageRenderer from "../../common/components/MessageRenderer";
import * as distributionActions from "./../actions/distributionActions.js";
import * as ourPropTypes from "./propTypes";
import SubmitButton from "../../common/components/SubmitButton";
import {DeviceInfoContext} from "../../mobile/DeviceInfo";

const PortfolioSelectionError = ({children}) => (
    <div className="portfolio-list-padding-fix">
        <h2 className="section-heading portfolio-list__mobile-header x-portfolio-header-mobile" data-identifier="valid">
            Portfolios</h2>
        <table style={{width: "100%", border: "0px"}} className="pure-table pure-table-horizontal portfolio-list"
               data-identifier="valid">
            <thead style={{backgroundColor: "transparent"}}>
            <tr>
                <th width="4%">
                    <input type="checkbox"
                           disabled={true}
                           style={{marginRight: "0.5em"}}/>
                </th>
                <th><span className="portfolio-list__heading x-portfolio-header">Portfolios</span></th>
                <th/>
                <th style={{textAlign: "right"}} className="portfolio-list__investment-amount-header">Investment
                    Amount
                </th>
            </tr>
            </thead>
            {children}
        </table>
    </div>
);

export const HardBlock = ({hardBlock}) => (
    <tbody className="portfolio-list__mandatory-errors portfolio-list__hard-block-error">
    <tr>
        <td colSpan={4}>
            <p>You can't enter that idea because:</p>
            <ul className="portfolio-list__validation-errors">
                <li className="portfolio-list__validation-error">{hardBlock}</li>
            </ul>
        </td>
    </tr>
    </tbody>
);

export const MandatoryIncompatible = ({classifiedPortfolios}) => (
    <tbody className="portfolio-list__mandatory-errors portfolio-list__mandatory-incompatible-error">
    <tr>
        <td colSpan={4}>
            <p>You can't enter that idea because it violates a rule on a mandatory portfolio:</p>
            <dl>
                {classifiedPortfolios.filter(cp => cp.classification === "mandatoryIncompatible").map(cp => ([
                    <dt key={cp.portfolio.id + "-key"}>{cp.portfolio.name}</dt>,
                    <dd key={cp.portfolio.id + "-value"}>
                        <ul className="portfolio-list__validation-errors">
                            {cp.errors.filter(err => !err.fixable).map(({message}, index) => (
                                <li key={index} className="portfolio-list__validation-error">{message}</li>
                            ))}
                        </ul>
                    </dd>
                ]))}
            </dl>
        </td>
    </tr>
    </tbody>
);


export const MandatoryFixable = ({classifiedPortfolios}) => (
    <tbody className="portfolio-list__mandatory-errors portfolio-list__mandatory-fixable-error">
    <tr>
        <td colSpan={4}>
            <p>Fix the following mandatory portfolio errors to proceed:</p>
            <dl>
                {classifiedPortfolios.filter(cp => cp.classification === "mandatoryFixable").map(cp => ([
                    <dt key={cp.portfolio.id + "-key"}>{cp.portfolio.name}</dt>,
                    <dd key={cp.portfolio.id + "-value"}>
                        <ul className="portfolio-list__validation-errors">
                            {cp.errors.map(({message}, index) => (
                                <li key={index} className="portfolio-list__validation-warning">{message}</li>
                            ))}
                        </ul>
                    </dd>
                ]))}
            </dl>
        </td>
    </tr>
    </tbody>
);

export const ConflictOfInterestBlock = ({conflictOfInterestMessage}) => {
    return conflictOfInterestMessage ? (
        <tbody className="portfolio-list__mandatory-errors">
        <tr>
            <td colSpan={4}>
                <p>Fix the following errors to proceed:</p>
                <ul className="portfolio-list__validation-errors">
                    <li className="portfolio-list__validation-error">{conflictOfInterestMessage}</li>
                </ul>
            </td>
        </tr>
        </tbody>
    ) : <tbody></tbody>;
};

// exported for testing
export const DisabledSubmitButton = ({validationsInFlight}) => (
    <div style={{textAlign: "right", marginTop: 14, paddingTop: 14}}
         className="portfolio-list-padding-fix">
        {validationsInFlight > 0 ?
         <span className="x-validations-in-flight" style={{padding: "0 1em"}}>Validating...</span> : null}
        <SubmitButton className="x-send-idea send-idea__send-button"
                      onSubmit={() => {
                      }}
                      disabled={true}
        >Send</SubmitButton>
    </div>
);

export class PortfolioSelection extends React.Component {
    constructor(props) {
        super(props);
        this._toggleAllPortfolios = this.toggleAllPortfolios.bind(this);
        this._includeOrExcludePortfolio = this.includeOrExcludePortfolio.bind(this);
        this._submitIdea = this.submitIdea.bind(this);
    }

    includeOrExcludePortfolio({portfolioId, checked}) {
        const {dispatch} = this.props;
        if (checked) {
            dispatch(distributionActions.includePortfolioForIdea(portfolioId));
        } else {
            dispatch(distributionActions.excludePortfolioFromIdea(portfolioId));
        }
    }

    get portfoliosWithoutErrors() {
        return this.props.classifiedPortfolios.filter(cp => cp.classification === "valid" ||
                                                            cp.classification === "mandatoryFixable" ||
                                                            cp.classification === "mandatoryIncompatible");
    }

    toggleAllPortfolios({checked}) {
        const {dispatch} = this.props;
        if (checked) {
            dispatch(distributionActions.includeAllPortfoliosForIdea());
        } else {
            const portfolioIds = this.portfoliosWithoutErrors.map(cp => cp.portfolio).map(p => p.id);
            dispatch(distributionActions.excludePortfoliosFromIdea(portfolioIds));
        }
    }

    submitIdea(event) {
        event.preventDefault();
        this.props.dispatch(distributionActions.distributeIdea(this.props.deviceInfo));
    }

    render() {
        const buttonText = this.props.isDistributingIdea ? "Sending..." : "Send";

        const fixablePortfolios = this.props.classifiedPortfolios.filter(cp => cp.classification === "fixable");
        const incompatiblePortfolios = this.props.classifiedPortfolios.filter(cp => cp.classification === "incompatible");

        const disableSubmit = this.props.missingOrInvalidDetails
                           || this.props.isDistributingIdea
                           || this.props.restrictedStocksLoadingOrStale
                           || (this.props.conflictOfInterestMessage !== null && this.props.conflictOfInterestMessage !== undefined);
        return <div>
            <PortfolioList classifiedPortfolios={this.portfoliosWithoutErrors}
                           onCheckboxChange={this._includeOrExcludePortfolio}
                           onToggleAllPortfolios={this._toggleAllPortfolios}
                           hardBlock={this.props.hardBlock}
                           heading="Portfolios"
                           identifier="valid"
                           rightHeading="Investment Amount"/>

            <table className="portfolio-list" data-identifier="valid">
                <ConflictOfInterestBlock conflictOfInterestMessage={this.props.conflictOfInterestMessage}/>
            </table>

            <div style={{textAlign: "right", borderTop: "1px solid #eee", marginTop: 14, paddingTop: 14}}
                 className="portfolio-list-padding-fix">
                {this.props.validationsInFlight > 0 ?
                 <span className="x-validations-in-flight" style={{padding: "0 1em"}}>Validating...</span> : null}
                <SubmitButton className="x-send-idea send-idea__send-button"
                              disabled={disableSubmit}
                              onSubmit={this._submitIdea}
                >{buttonText}</SubmitButton>
            </div>

            <MessageRenderer {...this.props.submissionStatus} />

            <PortfolioList heading="Fixable Problems"
                           identifier="not-valid"
                           classifiedPortfolios={fixablePortfolios}/>

            <PortfolioList heading="Not Compatible"
                           identifier="not-compatible"
                           classifiedPortfolios={incompatiblePortfolios}
                           incompatibleErrorsOnly={true}/>
        </div>;
    }
}

const Portfolios = (props) => {
    function portfolioWith(classification) {
        return props.classifiedPortfolios.filter(cp => cp.classification === classification).length > 0;
    }

    if (props.hardBlock) {
        return <div className="pure-1 pure-u-lg-1-2">
            <PortfolioSelectionError>
                <HardBlock hardBlock={props.hardBlock}/>
            </PortfolioSelectionError>
            <DisabledSubmitButton validationsInFlight={props.validationsInFlight}/>
        </div>;
    } else if (portfolioWith("mandatoryIncompatible")) {
        return <div className="pure-1 pure-u-lg-1-2">
            <PortfolioSelectionError>
                <MandatoryIncompatible classifiedPortfolios={props.classifiedPortfolios}/>
            </PortfolioSelectionError>
            <DisabledSubmitButton validationsInFlight={props.validationsInFlight}/>
        </div>;
    } else if (portfolioWith("mandatoryFixable")) {
        return <div className="pure-1 pure-u-lg-1-2">
            <PortfolioSelectionError>
                <MandatoryFixable classifiedPortfolios={props.classifiedPortfolios}/>
                <ConflictOfInterestBlock conflictOfInterestMessage={props.conflictOfInterestMessage}/>
            </PortfolioSelectionError>
            <DisabledSubmitButton validationsInFlight={props.validationsInFlight}/>
        </div>;
    } else {
        return <div className="pure-1 pure-u-lg-1-2">
            <DeviceInfoContext.Consumer>
                {deviceInfo => <PortfolioSelection {...props} deviceInfo={deviceInfo}/>}
            </DeviceInfoContext.Consumer>
        </div>;
    }
};

Portfolios.propTypes = {
    dispatch: PropTypes.func.isRequired,
    classifiedPortfolios: ourPropTypes.classifiedPortfolios.isRequired,
    isDistributingIdea: PropTypes.bool.isRequired,
    validationsInFlight: PropTypes.number.isRequired,
    submissionStatus: ourPropTypes.submissionStatus.isRequired,
    restrictedStocksLoadingOrStale: PropTypes.bool.isRequired,
    hardBlock: PropTypes.string
};

export default Portfolios;
