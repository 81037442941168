import * as R from "ramda";

function isAbsent(value) {
    return value === null || value === "";
}

export default function (portfolios, attributes) {
    return R.chain(portfolio => {
        const errors = [];


        if ( portfolio.isCommentaryRequired && attributes.commentary.trim().length === 0) {
            errors.push({
                forID: portfolio.id,
                message: "Commentary is required for this portfolio",
                fixable: true
            });
        }

        if (portfolio.isTargetPriceRequired && isAbsent(attributes.targetPrice)) {
            errors.push({
                forID: portfolio.id,
                message: "Target Price is required for this portfolio",
                fixable: true
            });
        }

        if (portfolio.isTimeHorizonRequired && attributes.timeHorizon === null) {
            errors.push({
                forID: portfolio.id,
                message: "Time Horizon is required for this portfolio",
                fixable: true
            });
        }

        return errors;
    }, portfolios);
}
