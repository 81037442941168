import queryString from "query-string";
import {Features} from "@timgroup/common-javascript-components";

export function makeFeatures(locationHash = window.location.hash) {
    const { ff: flagString = "" } = queryString.parse(locationHash.substring(locationHash.indexOf("?")));
    return new Features(flagString.split(","));
}

export function featureIsActive(feature) {
    return makeFeatures().testEnabled(feature);
}
