import {combineReducers} from "redux";
import reduceReducers from "reduce-reducers";
import * as R from "ramda";

import * as distributionActions from "./actions/distributionActions";
import {reducer as ideaAttachments} from "./actions/attachmentActions";
import {RECEIVED_USER_INFO} from "../userInfoActions";
import {isUserInteraction} from "../reducers";
import {INVESTMENT_SIZE_MEDIUM} from "./constants";

function determineStopLossAlert(state, action) {
    if (state.stopLossAlert === undefined || state.stopLossAlert === null || state.stopLossAlert === "") {
        return action.payload.defaultStopLossPercent ? action.payload.defaultStopLossPercent.toString() : "";
    } else {
        return state.stopLossAlert;
    }
}

const DEFAULT_IDEA_ATTRIBUTES = {
    ticker: "",
    typedTicker: "",
    companyName: "",
    stockPublicId: "",
    conflictOfInterest: null,
    direction: null,
    investmentSize: INVESTMENT_SIZE_MEDIUM,
    targetPrice: null,
    stopLossAlert: "",
    timeHorizon: null,
    commentary: "",
    webLink: ""
};

function ideaAttributes(state = DEFAULT_IDEA_ATTRIBUTES, action) {
    switch (action.type) {
        case distributionActions.CHANGE_IDEA_ATTRIBUTES:
        case distributionActions.CHANGE_STOCK_SYMBOL:
            return {...state, ...action.payload};
        case RECEIVED_USER_INFO:
            return {...state, stopLossAlert: determineStopLossAlert(state, action)};
        default:
            return state;
    }
}

function supportingInformation(state = false, action) {
    switch (action.type) {
        case "SHOW_SUPPORTING_INFORMATION":
            return true;
        case "HIDE_SUPPORTING_INFORMATION":
            return false;
        default:
            return state;
    }
}

function portfolios(state = [], action) {
    switch (action.type) {
        case distributionActions.RECEIVE_PORTFOLIOS:
            return action.payload;
        default:
            return state;
    }
}


function findOptionalPortfoliosIds(action) {
    return action.payload
        .filter(portfolio => !portfolio.isIdeaCreationDefaulted)
        .map(p => p.id);
}

function defaultPortfoliosExcludedFromIdea(state = [], action) {
    switch (action.type) {
        case distributionActions.RECEIVE_PORTFOLIOS:
            return findOptionalPortfoliosIds(action);
        default:
            return state;
    }
}

function portfoliosRequirements(state = [], action) {
    switch (action.type) {
        case distributionActions.RECEIVE_PORTFOLIOS_REQUIREMENTS_RESULTS:
            return action.payload;
        default:
            return state;
    }
}

function portfolioRuleValidationResult(state = {serverSideFailures: [], clientSideFailures: [], failures: [], investments: []}, action) {
    switch (action.type) {
        case distributionActions.RECEIVE_VALIDATION_RESULTS:
            state = R.mergeRight(state, action.payload);
            state.failures = [...state.clientSideFailures, ...state.serverSideFailures];
            break;
        case distributionActions.CLEAR_VALIDATION_RESULTS:
            state = {serverSideFailures: [], clientSideFailures: [], failures: [], investments: []};
            break;
    }
    return state;
}

function determinePortfolioExcludedFromIdea(state, action) {
    switch(action.type) {
        case distributionActions.RECEIVE_PORTFOLIOS:
            return R.difference(R.union(state.portfoliosExcludedFromIdea, findOptionalPortfoliosIds(action)), state.userDefaultedPortfolios);
        case RECEIVED_USER_INFO:
            return R.difference(state.portfoliosExcludedFromIdea, action.payload.defaultedPortfolios);
        case distributionActions.EXCLUDE_PORTFOLIOS_FROM_IDEA:
            return R.union(state.portfoliosExcludedFromIdea, action.payload);
        case distributionActions.INCLUDE_PORTFOLIO_FOR_IDEA:
            return state.portfoliosExcludedFromIdea.filter((portfolioId) => (portfolioId !== action.payload));
        case distributionActions.INCLUDE_ALL_PORTFOLIOS_FOR_IDEA:
            return [];
        default:
            return state.portfoliosExcludedFromIdea;
    }
}

function portfoliosExcludedFromIdea(state = {}, action) {
    return {...state, portfoliosExcludedFromIdea: determinePortfolioExcludedFromIdea(state, action)};
}

function validationsInFlight(state = 0, action) {
    switch(action.type) {
        case distributionActions.REQUEST_VALIDATION:
            return state + 1;
        case distributionActions.RECEIVE_VALIDATION_RESULTS:
            return action.payload.serverSideFailures ? state - 1 : state;
        case distributionActions.ABORT_VALIDATION:
            return state - 1;
        default:
            return state;
    }
}

function submissionStatus(state = { success: [], errors: [] }, action) {
    if (distributionActions.RECEIVE_FAILED_IDEA_DISTRIBUTION_RESULTS === action.type) {
        return action.payload;
    }
    if(isUserInteraction(action.type)) {
        return { success: [], errors: [] };
    }
    return state;
}

function isDistributingIdea(state = false, action) {
    switch(action.type) {
        case distributionActions.REQUEST_IDEA_DISTRIBUTION:
            return true;
        case distributionActions.RECEIVE_FAILED_IDEA_DISTRIBUTION_RESULTS:
            return false;
        default:
            return state;
    }
}

function tickerQueryResults(state = "", action) {
    switch(action.type) {
        case distributionActions.RECEIVE_TICKER_QUERY_RESULTS:
            return action.payload;
        default:
            return state;
    }
}

function userDefaultedPortfolios (state = [], action) {
    switch(action.type) {
        case RECEIVED_USER_INFO:
            return action.payload.defaultedPortfolios;
        default:
            return state;
    }
}

const noop = state => state || [];

export default reduceReducers(
    portfoliosExcludedFromIdea,
    combineReducers({
        ideaAttributes,
        ideaAttachments,
        supportingInformation,
        portfolios,
        portfoliosRequirements,
        portfolioRuleValidationResult,
        defaultPortfoliosExcludedFromIdea,
        userDefaultedPortfolios,
        validationsInFlight,
        submissionStatus,
        isDistributingIdea,
        tickerQueryResults,
        portfoliosExcludedFromIdea: noop,
    })
);
