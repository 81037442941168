import React, {useCallback, useDebugValue, useEffect, useState} from "react";

export function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = useCallback(() => {
        setWindowWidth(window.innerWidth);
    }, []);
    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useDebugValue(windowWidth);
    return windowWidth;
}
