import React from "react";
import {useSelector} from "react-redux";
import IdeaDistribution from "../distribution/components/IdeaDistribution";
import IdeaManagement from "../management/components/IdeaManagement";
import StaleRestrictedStocksWarning from "../components/StaleRestrictedStocksWarning";
import TabBar, {TAB_VIEWS} from "./tabBar/TabBar";

const tabContent = new Map([
    [
        TAB_VIEWS.createIdea,
        <div>
            <h2 className="section-heading">Send Idea</h2>
            <IdeaDistribution />
        </div>
    ],
    [
        TAB_VIEWS.manageIdeas,
        <div>
            <h2 className="section-heading">Open Ideas</h2>
            <IdeaManagement />
        </div>
    ]
]);

const MobilePhoneHome = ({}) => {
    const [companyPublicId, restrictedStocksAreStale, selectedTabBarItem] = useSelector(state => ([
        state.userCompanyPublicId,
        state.restrictedStocksAreStale,
        state.userInterface.tabBar.selected
    ]));

    const view = tabContent.get(selectedTabBarItem) || tabContent.get(TAB_VIEWS.createIdea);

    return (
        <div>
            <StaleRestrictedStocksWarning restrictedStocksAreStale={restrictedStocksAreStale}
                                          companyPublicId={companyPublicId}/>
            {view}
            <TabBar current={selectedTabBarItem}/>
        </div>
    );
};

export default MobilePhoneHome;
