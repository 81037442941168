export const CHANGE_PASSWORD_ERRORS = "CHANGE_PASSWORD_ERRORS";
export const PASSWORD_CHANGED = "PASSWORD_CHANGED";
export const TEMPORARY_PASSWORD_SENT = "TEMPORARY_PASSWORD_SENT";

export function changePasswordErrors(errors) {
    return {
        type: CHANGE_PASSWORD_ERRORS,
        payload: errors
    };
}

export function passwordChanged() {
    return {
        type: PASSWORD_CHANGED
    };
}

