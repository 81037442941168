import ajax from "./authenticationSafeAjax";

export const RECEIVED_USER_INFO = "RECEIVED_USER_INFO";

export function fetch() {
    return dispatch => {
        ajax({
                url: "/TradeIdeasMonitor/ui/api/userInfo",
                method: 'GET'
            },
            (status, text) => dispatch(receivedUserInfo(text)));
    }
}

export function receivedUserInfo(userInfo) {
    return {
        type: RECEIVED_USER_INFO,
        payload: userInfo
    }
}

