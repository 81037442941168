import moment from "moment";


function parse(dateString) {
    return moment(dateString);
}

export function printShort(dateString) {
    return parse(dateString).format("D MMM");
}

export function printLong(dateString) {
    return parse(dateString).format("D MMM YYYY HH:mm");
}

export function printDate(dateString) {
    return parse(dateString).format("D MMM YYYY");
}

export function printTime(dateString) {
    return parse(dateString).format("HH:mm");
}
