import * as RestrictedStockActions from "./restrictedStocksActions";

export function restrictedStocks(state = null, action) {
    if (RestrictedStockActions.RECEIVED_RESTRICTED_STOCKS === action.type) {
        return action.payload;
    } else {
        return state;
    }
}

export function restrictedStocksAreStale(state = false, action) {
    switch (action.type) {
        case RestrictedStockActions.RESTRICTED_STOCKS_STALE:
            return true;
        case RestrictedStockActions.RECEIVED_RESTRICTED_STOCKS:
            return false;
        default:
            return state;
    }
}