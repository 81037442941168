import React from "react";
import PropTypes from "prop-types";
import RestrictedStockCodes from "../../restrictedStocks/RestrictedStockCodes";

export function isRestricted(restrictedStocks, stockPublicId) {
    return restrictedStocks && stockPublicId && restrictedStocks[stockPublicId];
}

export const RestrictionCodes = ({restrictedStocks, stockPublicId}) => {
    if (!isRestricted(restrictedStocks, stockPublicId)) {
        return <div/>;
    }
    return <div className="pure-control-group">
        <label/>
        <RestrictedStockCodes codes={restrictedStocks[stockPublicId]}/>
    </div>;
};

RestrictionCodes.propTypes = {
    restrictedStocks: PropTypes.object,
    stockPublicId: PropTypes.string
};
