import React, {useCallback} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import * as TabBarActions from "./tabBarActions";
import {useDeviceInfo} from "../DeviceInfo";
import createIconOn from "../../../images/nav-create-blue.svg";
import createIconOff from "../../../images/nav-create-grey.svg";
import manageIconOn from "../../../images/nav-manage-blue.svg";
import manageIconOff from "../../../images/nav-manage-grey.svg";

export const TAB_VIEWS = {
    createIdea: "createIdea",
    manageIdeas: "manageIdeas"
};

const tabBarItemStyles = (props) => {
    return {
        padding: "10px",
        display: "inline-block",
        textDecoration: "none",
        color: props.current ? "#4a90e2" : "#9b9b9b",
        textAlign: "center"
    };
};

const TabBarIcon = styled.img`
    margin-bottom: 5px;
`;

export const TabBarItem = props => {
    let icon = null;
    if (props.to === TAB_VIEWS.createIdea) {
        icon = props.current ? <TabBarIcon src={createIconOn} className="t-create-idea-tab-on"/>
                             : <TabBarIcon src={createIconOff} className="t-create-idea-tab-off"/>;
    } else if (props.to === TAB_VIEWS.manageIdeas) {
        icon = props.current ? <TabBarIcon src={manageIconOn} className="t-manage-idea-tab-on"/>
                             : <TabBarIcon src={manageIconOff} className="t-manage-idea-tab-off"/>;
    }

    return (
        <div style={tabBarItemStyles(props)} className={props.className} onClick={() => props.onClick(props.to)}>
            {icon}
            <br/>
            {props.name}
        </div>
    );
};

const navBarStyles = {
    backgroundColor: "#eee",
    borderTop: "1px solid #ddd",
    position: "fixed",
    bottom: 0,
    width: "100%",
    height: "70px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "flex-end"
};

const wrapperStyles = {
    height: navBarStyles.height
};

const TabBar = ({}) => {
    const deviceInfo = useDeviceInfo();
    const dispatch = useDispatch();
    const current = useSelector(state => state.userInterface.tabBar.selected);
    const onSelect = useCallback(item => {
        dispatch(TabBarActions.selectTabBarItem(item))
    }, [dispatch]);

    if (!deviceInfo.isMobilePhone)
        return <div/>;

    return (
        <div style={wrapperStyles}>
            <div style={navBarStyles}>
                <TabBarItem to={TAB_VIEWS.createIdea}
                            name="Create Idea"
                            className="x-tab-bar-item-create-idea"
                            current={current === TAB_VIEWS.createIdea}
                            onClick={onSelect}/>
                <TabBarItem to={TAB_VIEWS.manageIdeas}
                            name="Manage Ideas"
                            className="x-tab-bar-item-manage-ideas"
                            current={current === TAB_VIEWS.manageIdeas}
                            onClick={onSelect}/>
            </div>
        </div>
    );
};

export default TabBar;

