import ajax from "./authenticationSafeAjax";
import environment from "./util/getEnvironment";
import {appVersion as clientAppVersion} from "@timgroup/common-javascript-components";

export function log(subtype, description, customFields={}) {
    sendLogEventTo("/TradeIdeasMonitor/ui/api/log", subtype, description, customFields);
}

export function logUnauthorised(subtype, description, customFields={}) {
    sendLogEventTo("/TradeIdeasMonitor/ui/api/logUnauthorised", subtype, description, customFields);
}

export function logCreateIdea(description) {
    log("ui-revamp-create-idea", description);
}

export const CLOSE_IDEA_STATUS = {
    FULL: "FULL",
    PARITAL: "PARTIAL",
    ERROR: "ERROR"
};

export function logCloseIdeaFull(ticker, companyName, bucketIdsToClose) {
    logCloseIdea(CLOSE_IDEA_STATUS.FULL, ticker, companyName, bucketIdsToClose,
        `The idea for ${companyName} (${ticker}) was fully closed for ${bucketIdsToClose.length} portfolios.`,
        { bucketIdsToClose } );
}

export function logCloseIdeaPartial(ticker, companyName, bucketIdsToClose, bucketIdsRemainingOpen) {
    const numberOfPortfoliosClosed = bucketIdsToClose.length - bucketIdsRemainingOpen.length;

    logCloseIdea(CLOSE_IDEA_STATUS.PARITAL, ticker, companyName, bucketIdsToClose,
                 `The idea for ${companyName} (${ticker}) was closed for ${numberOfPortfoliosClosed} of ${bucketIdsToClose.length} portfolios.`,
                 { bucketIdsRemainingOpen });
}

export function logCloseIdeaError(ticker, companyName, bucketIdsToClose, errors) {
    logCloseIdea(CLOSE_IDEA_STATUS.ERROR, ticker, companyName, bucketIdsToClose,
                 `Error closing the idea for ${companyName} (${ticker}).`,
                 { errors });
}

export function logLogin(description) {
    logUnauthorised("ui-v2-login", description);
}

export function logOpenScreen(description) {
    log("ui-v2-open-screen", description);
}

export function logChangeIdeaAttributes(description) {
    log("ui-v2-change-idea-attributes", description);
}

export function logChangePortfolioSelection(description) {
    log("ui-v2-change-portfolio-selection", description);
}

export function logSizeTipOpen() {
    log("ui-v2-size-tip-open", "Investment Size Info Tip was opened.");
}

export function logSizeTipClose() {
    log("ui-v2-size-tip-close", "Investment Size Info Tip was closed.");
}

function sendLogEventTo(logApiUrl, subtype, description, customFields={}) {
    const client_timestamp = new Date().toISOString();
    const request = {
        headers: { "Content-Type": "application/json" },
        url: logApiUrl,
        method: 'POST',
        body: {
            iframe_host: environment.TIM2 ? "TIM2" : "",
            type: "TimIdeasClientEvent",
            subtype,
            client_timestamp,
            client_app_version: clientAppVersion,
            description,
            user_agent: window.navigator.userAgent,
            ...customFields
        }
    };

    ajax(request, (statusCode, body) => {});
}

function logCloseIdea(status, ticker, companyName, bucketIdsToClose, description, optionalFields={}) {
    const mandatoryFields = {
        ticker,
        companyName,
        closeStatus: status,
        bucketIdsToClose
    };
    log("ui-v2-close-idea", description, ({...mandatoryFields, ...optionalFields}));
}
