import htmlparser from "htmlparser";

export default function(html) {
    const handler = new htmlparser.DefaultHandler(error => {
        if (error) {
            console.log("Error: " + error);
        } else {
            // do nothing special
        }
    }, { verbose: false, ignoreWhitespace: true });

    const parser = new htmlparser.Parser(handler);
    parser.parseComplete(html);

    const result = htmlparser.DomUtils.getElements({ class: "requirements-attribute" }, handler.dom);

    const priceMethod = result.filter(attribute => {
        const title = htmlparser.DomUtils.getElements({ class: "requirements-attribute-title" }, attribute);
        return title.length > 0 && title[0].children[0].data.startsWith("Price method:");
    });

    if (priceMethod.length > 0) {
        const value = htmlparser.DomUtils.getElements({ class: "requirements-attribute-value" }, priceMethod)[0];
        return value.children[0].data;
    }

    return "Touch price";
};