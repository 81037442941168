import React, {useEffect, useReducer, useRef} from "react";
import {Redirect, Route, Switch, useHistory, useParams, useRouteMatch} from "react-router";
import {useSelector} from "react-redux";

import SignIn from "./authentication/components/SignIn.js";
import ChangePassword from "./authentication/components/ChangePassword";
import {requestSessionStatus} from "./authentication/actions/authenticationWithTIM";
import Home from "./components/Home";
import MessageRenderer from "./common/components/MessageRenderer";

export const TIMRedirector = ({page}) => {
    const history = useHistory();
    const params = useParams();
    const {path} = useRouteMatch();
    const formElt = useRef(null);
    useEffect(() => {
        requestSessionStatus((isSignedOn) => {
            if (isSignedOn) {
                formElt.current.submit();
            }
            else {
                history.replace({
                    pathname: '/sign-in',
                    state: { nextPathname: path }
                })
            }
        });
    }, []);

    return (
        <form ref={formElt} method="POST" action="/TradeIdeasMonitor/monitor" style={{display: "none"}}>
            <input name="page" value={page} readOnly/>
            <input name="action" value="open" readOnly/>
            {Object.entries(params).map(([name, value]) => (
                <input key={name} name={name} value={value} readOnly/>
            ))}
        </form>
    );
};

const RequireAuthentication = ({children}) => {
    const [[resolved, signedOn], dispatch] = useReducer((state, isSignedOn) => [true, isSignedOn], [false, null]);
    useEffect(() => {
        requestSessionStatus(dispatch);
    }, []);
    if (!resolved)
        return null;
    if (signedOn)
        return children();
    return <Redirect to="/sign-in" />;
};

const RequireNoAuthentication = ({redirectTo = "/home", children}) => {
    const [[resolved, signedOn], dispatch] = useReducer((state, isSignedOn) => [true, isSignedOn], [false, null]);
    useEffect(() => {
        requestSessionStatus(dispatch);
    }, []);
    if (!resolved)
        return null;
    if (!signedOn)
        return children();
    return <Redirect to={redirectTo}/>;
};

const MessageRenderWithMessages = ({}) => {
    const {errors = [], warnings = [], success = []} = useSelector(state => state.messages);
    return <MessageRenderer position="static" errors={errors} warnings={warnings} success={success}/>;
};

const Root = () => (
    <div>
        <MessageRenderWithMessages/>
        <Switch>
            <Route exact path="/">
                <Redirect to="/sign-in" />
            </Route>
            <Route exact path="/sign-in">
                <RequireNoAuthentication>{() => <SignIn/>}</RequireNoAuthentication>
            </Route>
            <Route exact path="/change-password">
                <RequireAuthentication>{() => <ChangePassword/>}</RequireAuthentication>
            </Route>
            <Route exact path="/home">
                <RequireAuthentication>{() => <Home/>}</RequireAuthentication>
            </Route>
            <Route exact path="/createidea">
                <TIMRedirector page="com.youdevise.tradeideas.controller.linkedideas.UIRevampCreateIdeaController"/>
            </Route>
            <Route exact path="/myperformance">
                <TIMRedirector page="com.youdevise.tradeideas.controller.myperformance.MyPerformanceController"/>
            </Route>
            <Route exact path="/viewideas">
                <TIMRedirector page="com.youdevise.tradeideas.servlet.ReviewIdeasController"/>
            </Route>
            <Route exact path="/pendingideas">
                <TIMRedirector page="com.youdevise.tradeideas.servlet.PendingIdeasController"/>
            </Route>
            <Route exact path="/brokerrankings">
                <TIMRedirector page="com.youdevise.tradeideas.externalapps.RankingsAppController"/>
            </Route>
            <Route exact path="/portfoliorankings">
                <TIMRedirector page="com.youdevise.tradeideas.controller.ViewPortfoliosController"/>
            </Route>
        </Switch>
    </div>
);

export default Root;
