import {combineReducers} from "redux";
import {v4 as uuidv4} from "uuid";

import * as MgmtActions from "./actions/managementActions.js";
import {isUserInteraction} from "../reducers";

function closeIdeaSubmissionStatus(state = { success: [], errors: [], warnings: [] }, action) {
    if (MgmtActions.RECEIVE_SUCCESSFUL_CLOSE_IDEA_RESULTS === action.type) {
        return {success: action.payload.successMessages, errors: [], warnings: []};
    }
    if (MgmtActions.RECEIVE_ERROR_CLOSE_IDEA_RESULTS === action.type) {
        return {success: [], errors: action.payload.errorMessages, warnings: []};
    }
    if (MgmtActions.RECEIVE_WARNING_CLOSE_IDEA_RESULTS === action.type) {
        return {success: [], errors: [], warnings: action.payload.messages};
    }
    if(isUserInteraction(action.type)) {
        return {success: [], errors: [], warnings: []};
    }
    return state;
}

function openIdeas(state = [], action = undefined) {
    switch(action.type) {
        case MgmtActions.RECEIVE_OPEN_IDEAS:
            return action.payload.map(idea => ({...idea, showingCloseIdea: false, closeInProgress: false, uuid: uuidv4()}));
        case MgmtActions.RECEIVE_SUCCESSFUL_CLOSE_IDEA_RESULTS:
            const bucketIdsForIdeaToClose = action.payload.bucketIds;

            return state.filter(idea => {
                return idea.bucketIds.indexOf(bucketIdsForIdeaToClose[0]) === -1;
            }).map(hideCloseIdeaFor(action.payload.ideaUUID))
                .map(finishedClosingIdeaFor(action.payload.ideaUUID));
        case MgmtActions.RECEIVE_WARNING_CLOSE_IDEA_RESULTS:
            const { bucketIdsRemainingOpen } = action.payload;

            return state.map(idea => {
                const isThePartiallyClosedIdea = idea.bucketIds.indexOf(bucketIdsRemainingOpen[0]) !== -1;
                if (isThePartiallyClosedIdea) {
                    idea.bucketIds = bucketIdsRemainingOpen;
                }
                return idea;
            }).map(hideCloseIdeaFor(action.payload.ideaUUID))
                .map(finishedClosingIdeaFor(action.payload.ideaUUID))  ;
        case MgmtActions.RECEIVE_ERROR_CLOSE_IDEA_RESULTS:
            return state.map(finishedClosingIdeaFor(action.payload.ideaUUID));
        case MgmtActions.TOGGLE_CLOSE_IDEA:
            return state.map(idea => {
                if (idea.uuid === action.payload.ideaUUID) {
                    return ({...idea, showingCloseIdea: !idea.showingCloseIdea});
                } else {
                    return idea;
                }
            });
        case MgmtActions.CANCEL_CLOSE_IDEA:
            return state.map(hideCloseIdeaFor(action.payload.ideaUUID));
        case MgmtActions.START_CLOSE_IDEA:
            return state.map(closingIdeaFor(action.payload.ideaUUID));
        default:
            return state;
    }
}

function closingIdeaFor(ideaUUID) {
    return (idea) => {
        if (idea.uuid === ideaUUID) {
            return ({...idea, closeInProgress: true});
        } else {
            return idea;
        }
    }
}

function finishedClosingIdeaFor(ideaUUID) {
    return (idea) => {
        if (idea.uuid === ideaUUID) {
            return ({...idea, closeInProgress: false});
        } else {
            return idea;
        }
    }
}

function hideCloseIdeaFor(ideaUUID) {
    return (idea) => {
        if (idea.uuid === ideaUUID) {
            return ({...idea, showingCloseIdea: false});
        } else {
            return idea;
        }
    }
}

export default combineReducers({
    openIdeas,
    closeIdeaSubmissionStatus
});
