import {observeAjax} from "../../authenticationSafeAjax";

function transformPortfolios(portfolios) {
    return portfolios.map(portfolio => {
        return {
            id: portfolio.id,
            name: portfolio.name,
            currency: portfolio.investmentLimitCurrency,
            isCurrencyLocked: portfolio.isCurrencyLocked,
            isCommentaryRequired: portfolio.isCommentaryRequired,
            isTargetPriceRequired: portfolio.isTargetPriceRequired,
            isTimeHorizonRequired: portfolio.isTimeHorizonRequired,
            isIdeaCreationRequired: portfolio.createIdea === "REQUIRED",
            isIdeaCreationDefaulted: portfolio.createIdea === "DEFAULTED" || portfolio.createIdea === "REQUIRED",
            indicativeInvestments: { SMALL: portfolio.smallInvestment,
                                     MEDIUM: portfolio.mediumInvestment,
                                     LARGE: portfolio.largeInvestment }
        };
    });
}

/**
 * Please note that this explicitly does a POST to avoid a caching problem with doing a GET in IE
 */
export function fetchPortfoliosFromTIM() {
    return observeAjax({
        url: "/TradeIdeasMonitor/monitor?customAction=myPortfolios&includeInvestments=true",
        method: "POST"
    }).map(({data: {portfolios}}) => transformPortfolios(portfolios));
}
