import submitCloseIdeaRequestToTIM from "./submitCloseIdeaRequestToTIM";
import * as DistributionActions from "../../distribution/actions/distributionActions";
import * as logEventToTIM from "../../logEvent";

export const RECEIVE_OPEN_IDEAS = "RECEIVE_OPEN_IDEAS";
export const RECEIVE_SUCCESSFUL_CLOSE_IDEA_RESULTS = "RECEIVE_SUCCESSFUL_CLOSE_IDEA_RESULTS";
export const RECEIVE_ERROR_CLOSE_IDEA_RESULTS = "RECEIVE_ERROR_CLOSE_IDEA_RESULTS";
export const RECEIVE_WARNING_CLOSE_IDEA_RESULTS = "RECEIVE_WARNING_CLOSE_IDEA_RESULTS";
export const START_CLOSE_IDEA = "START_CLOSE_IDEA";
export const CANCEL_CLOSE_IDEA = "CANCEL_CLOSE_IDEA";
export const TOGGLE_CLOSE_IDEA = "TOGGLE_CLOSE_IDEA";

export function loadOpenIdeas(fetchOpenIdeas) {
    return (dispatch, getState) => {
        fetchOpenIdeas(openIdeas => {
            dispatch(receiveOpenIdeas(openIdeas));
        });
    };
}

export function receiveOpenIdeas(openIdeas) {
    return {
        type: RECEIVE_OPEN_IDEAS,
        payload: openIdeas
    };
}

export function submitCloseIdea(ticker, companyName, commentary, bucketIdsToClose, deviceInfo, ideaUUID,
                                submitCloseIdeaRequest = submitCloseIdeaRequestToTIM, logEvent = logEventToTIM) {
    return (dispatch, getState) => {

        dispatch(startCloseIdea(ideaUUID));

        submitCloseIdeaRequest(commentary, bucketIdsToClose, deviceInfo, ({ errors, bucketIdsRemainingOpen }) => {
            if (errors.length !== 0) {
                dispatch(receiveErrorCloseIdeaResults(ideaUUID, bucketIdsToClose, errors));
                logEvent.logCloseIdeaError(ticker, companyName, bucketIdsToClose, errors);
                return;
            }

            if (bucketIdsRemainingOpen.length === 0) {
                dispatch(receiveSuccessfulCloseIdeaResults(ideaUUID, bucketIdsToClose, ["Your idea was closed successfully."]));
                logEvent.logCloseIdeaFull(ticker, companyName, bucketIdsToClose);
            } else {
                const portfolioText = "portfolio" + (bucketIdsRemainingOpen.length === 1 ? "" : "s");
                let warning = `The idea was not closed for ${bucketIdsRemainingOpen.length} ${portfolioText}.`;
                dispatch(receiveWarningCloseIdeaResults(ideaUUID, bucketIdsRemainingOpen, [warning]));
                logEvent.logCloseIdeaPartial(ticker, companyName, bucketIdsToClose, bucketIdsRemainingOpen);
            }

            dispatch(DistributionActions.validateIdeaAttributesAgainstPortfolioRules());
        });
    };
}

export function startCloseIdea(ideaUUID) {
    return {
        type: START_CLOSE_IDEA,
        payload: { ideaUUID }
    };
}

export function cancelCloseIdea(ideaUUID) {
    return {
        type: CANCEL_CLOSE_IDEA,
        payload: { ideaUUID }
    };
}

export function showCloseIdea(ideaUUID) {
    return {
        type: TOGGLE_CLOSE_IDEA,
        payload: { ideaUUID }
    };
}

export function receiveSuccessfulCloseIdeaResults(ideaUUID, bucketIds, successMessages) {
    return {
        type: RECEIVE_SUCCESSFUL_CLOSE_IDEA_RESULTS,
        payload: { ideaUUID, bucketIds, successMessages }
    };
}

export function receiveErrorCloseIdeaResults(ideaUUID, bucketIds, errorMessages) {
    return {
        type: RECEIVE_ERROR_CLOSE_IDEA_RESULTS,
        payload: { ideaUUID, bucketIds, errorMessages }
    };
}

export function receiveWarningCloseIdeaResults(ideaUUID, bucketIdsRemainingOpen, messages) {
    return {
        type: RECEIVE_WARNING_CLOSE_IDEA_RESULTS,
        payload: { ideaUUID, bucketIdsRemainingOpen, messages }
    };
}
