export function postfix(object, postfix = "") {
    const postfixedObject = {};
    for (const [key, value] of Object.entries(object)) {
        postfixedObject[key + postfix] = value;
    }
    return postfixedObject;
}

export function tickerFor(validationAttributes) {
    return {
        TICKER: encodeURIComponent(validationAttributes.ticker || "Search..."),
        INVESTMENT: undefined,
        STOCK_ID: undefined,
        TARGET_PRICE: validationAttributes.targetPrice ? encodeURIComponent(validationAttributes.targetPrice) : undefined,
        DIRECTION: validationAttributes.direction
    };
}

export function commonValues(stopLossAlert, timeHorizon, conviction, size) {
    return {
        COMMON_VALUES_CATEGORY: "TR",
        COMMON_VALUES_SIZE: size,
        COMMON_VALUES_STOP_LOSS: stopLossAlert === "" ? undefined : stopLossAlert + encodeURIComponent("%"),
        COMMON_VALUES_CONVICTION: conviction,
        COMMON_VALUES_TIME_HORIZON: timeHorizon ? encodeURIComponent(timeHorizon) : undefined,
        COMMON_VALUES_STYLE: undefined,
        COMMON_VALUES_PROBABILITY: undefined
    };
}

export function commentary(commentary) {
    return {
        COMMENTARY_IDEA_TITLE: undefined,
        COMMENTARY_WEB_LINK: undefined,
        COMMENTARY_COMMENTARY: encodeURIComponent(commentary),
        COMMENTARY_VARIANT_VIEW: undefined
    };
}

export function miscellaneous() {
    return {
        recommendationOrder: undefined,
        cachedIds: undefined,
        binaryDataIds: undefined,
        removedBinaryDataIds: undefined,
        attachmentNames: undefined
    };
}

export function miscellaneousForValidation() {
    return {
        "groups-section-select-all": "on",
        PUBLISH: undefined,
        SAVE_DRAFT: undefined,
        action: undefined,
        customAction: "linkedIdeaGroupRuleValidationAction"
    };
}


export function ideaGroups(portfolioId) {
    return {
        IDEA_GROUPS_SELECT: portfolioId,
        IDEA_GROUPS_IS_CURRENCY_LOCKED: "false",
        IDEA_GROUPS_PORTFOLIO_ID: portfolioId,
        IDEA_GROUPS_UNIQUE_ID: portfolioId,
        IDEA_GROUPS_DISCLAIMER: undefined,
        IDEA_GROUPS_DEFAULT_INVESTMENT: undefined,
        IDEA_GROUPS_DEFAULT_INVESTMENT_CURRENCY: "USD"
    };
}

export function investment(portfolioId, amount, currency) {
    return {
        [`INVESTMENT_DIRECTLY_SET-${portfolioId}`]: "false",
        [`INVESTMENT-${portfolioId}`]: encodeURIComponent(amount),
        [`INVESTMENT_CURRENCY-${portfolioId}`]: currency
    };
}

export function toParamString(object) {
    const entries = [];
    for (const [key, value] of Object.entries(object)) {
        entries.push(`${key}=${value || ""}`);
    }
    return entries.join("&");
}

export function ideaGroupsAndInvestmentsFor(portfolios) {
    return portfolios.reduce(function (resultObject, portfolio, index) {
        return Object.assign(
            resultObject,
            postfix(ideaGroups(portfolio.id), "_" + index),
            postfix(investment(portfolio.id, portfolio.investmentAmount, portfolio.currency), "-0")
        );
    }, {});
}

export function validationFormParamsFor(portfolios, validationAttributes) {
    return toParamString(({
        ...postfix(tickerFor(validationAttributes), "0"),
        ...postfix(commonValues(validationAttributes.stopLossAlert, undefined, validationAttributes.conviction, validationAttributes.size), "_0"),
        ...postfix(commentary(validationAttributes.commentary), "_0"),
        ...miscellaneous(),
        ...miscellaneousForValidation(),
        ...ideaGroupsAndInvestmentsFor(portfolios)
    }));
}

export function tickerQueryFormParamsFor(ticker) {
    return toParamString({
        customAction: "getStockSearch",
        query: ticker ? encodeURIComponent(ticker) : "",
        tickersToExclude: undefined,
        includeDelisted: false,
        includeInactiveExchanges: false,
        disallowIdeasFromInactiveExchanges: true,
        includeOnlyConsolidated: true
    });
}
