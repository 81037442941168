export default function (value) {
    if (value === null || value === undefined || value === "") {
        return "";
    }

    const parsed = parseFloat(value);

    if (isNaN(parsed)) {
        return "invalid";
    }

    if (!value.match(/^[\d.]+$/)) {
        return "invalid";
    }

    if (value.split(".").length > 2) {
        return "invalid";
    }

    return "";
}
