import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {ERROR_COLOR} from "../common/constants";

import RestrictedStock from "./RestrictedStock";

const Warning = styled.span`
    vertical-align: middle;
    color: ${ERROR_COLOR};
    font-weight: bold;
    font-size: 80%;
`;

const RestrictedStockCodes = ({codes}) => {
    return <span>
            <RestrictedStock/>
            <Warning className="t-restricted-stock-codes">Restricted Stock: {codes.join(", ")}</Warning>
        </span>;
};

RestrictedStockCodes.propTypes = {
    codes: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default RestrictedStockCodes;
