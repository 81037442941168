import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import validateFloat from "./validateFloat";

const TargetPrice = (props) => (
    <div className={classNames("pure-control-group", "target-price", "x-target-price", validateFloat(props.value), props.requiredClass)}>
        <label htmlFor="target">Target Price</label>
        <input id="target"
               className="x-target-price-input idea-attribute__input"
               type="number"
               step="0.001"
               value={props.value || ""}
               onChange={(event) => props.onChange(event.currentTarget.value)}
               style={{width: "4em"}} />
    </div>
);
TargetPrice.propTypes = {
    onChange: PropTypes.func.isRequired,
    requiredClass: PropTypes.string.isRequired,
    value: PropTypes.string
};

export default TargetPrice;