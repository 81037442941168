import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Commentary = ({requiredClass, id, label, value, onChange}) => (
    <div className={classNames("pure-control-group", requiredClass)}>
        <label htmlFor={id}>{label}</label>
        <textarea id={id}
                  className="x-comment-input idea-attribute__input"
                  style={{width: "268px"}}
                  value={value}
                  onChange={(event) => onChange(event.currentTarget.value)}
                  rows="5"/>
    </div>
);
Commentary.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    requiredClass: PropTypes.string
};

export default Commentary;