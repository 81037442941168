import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import acurisIcon from "../../../images/acuris-equities-ios_76x76.png";

const Container = styled.div`
    height: 84px;
`;

const BackgroundContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: #28313e;
    color: white;
    padding: 0.75em;
`;

const AcurisIconImg = styled.img`
    width: 60px;
    height: 60px;
`;

const InstallText = styled.span`
    margin-right: auto;
    margin-left: 10px;
`;

const Caret = styled.span`
    transform: rotate(90deg);
    display: inline-block;
    font-size: 150%;
    font-family: Arial;
`;

export const AddToHomescreenBanner = ({isExpanded, onClickBanner, instructions}) => (
    <Container>
        <div
            className={classNames("add-to-homescreen x-add-to-homescreen", {"add-to-homescreen--expanded": isExpanded})}
            onClick={onClickBanner}>
            <BackgroundContainer className="x-add-to-homescreen-header">
                <AcurisIconImg src={acurisIcon}/>
                <InstallText>Install the TIM app</InstallText>
                <Caret>»</Caret>
            </BackgroundContainer>
            {instructions}
        </div>
    </Container>
);