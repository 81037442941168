import Observable from "zen-observable";
import {isHttpError, isNetworkError, observeAjax} from "../../authenticationSafeAjax";

export default function(portfolios, {ideaAttributes, portfoliosExcludedFromIdea, portfolioRuleValidationErrors}) {
    const data = {
        ideaAttributes: ideaAttributes,
        portfoliosExcludedFromIdea: portfoliosExcludedFromIdea,
        portfolioRuleValidationErrors: portfolioRuleValidationErrors,
        portfoliosToReceiveIdea: portfolios.map(({ id, name, currency }) => ({ id, name, currency }))
    };
    const request = {
        url: "/TradeIdeasMonitor/ui/api/createIdea",
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: data,
        timeout: 60000
    };

    return new Observable(observer =>
        observeAjax(request).subscribe(
            ({data}) => {
                observer.next(data);
            },
            ({code: statusCode}) => {
                if (isHttpError(statusCode)) {
                    observer.next({errors: ["Unable to send your idea due to an unexpected error: HTTP " + statusCode]});
                } else if (isNetworkError(statusCode)) {
                    observer.next({errors: ["There was a problem sending your idea."]});
                }
                observer.complete();
            },
            () => {
                observer.complete();
            }
        ))
}
