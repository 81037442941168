import * as R from "ramda";
import {appVersion as clientAppVersion} from "@timgroup/common-javascript-components";

const distributionTransformations = {
    isDistributingIdea() {
        return false;
    },
    validationsInFlight() {
        return 0
    }
};

const managementTransformations = {
    openIdeas(ideas) {
        return ideas.map(idea => ({ ...idea, closeInProgress: false}))
    }
};

const stateTreeTransformations = {
    distribution: R.evolve(distributionTransformations),
    management: R.evolve(managementTransformations)
};

export default function (initial, persisted) {
    if (persisted.clientAppVersion === clientAppVersion) {
        return R.evolve(stateTreeTransformations, persisted);
    }

    return initial;
}
