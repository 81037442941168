import { log } from "./logEvent";
import StackTrace from "stacktrace-js";

window.onerror = function(errorMsg, sourceUrl, lineNumber, columnNumber, errorObject) {
    const errorEvent = { url: sourceUrl, lineNumber: lineNumber };

    if (errorObject) {
        StackTrace.fromError(errorObject)
            .then(stackFrames => {
                errorEvent.originalError = errorObject.originalError;
                errorEvent.stackTrace = stackFrames.map(frame => frame.toString()).join("\n");
                log("ui-v2-javascript-error", errorMsg, errorEvent);
            })
            .catch(err => {
                const errorEvent = { originalError: err };
                log("ui-v2-javascript-error", "Failed to decode stack trace.", errorEvent);
            });
    } else {
        log("ui-v2-javascript-error", errorMsg, errorEvent);
    }

};
