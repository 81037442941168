import React from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import MessageRenderer from "../../common/components/MessageRenderer";
import OpenIdea from "./OpenIdea";

function restrictionsFor(restrictedStocks, stockPublicId) {
    if (restrictedStocks === null) {
        return null;
    }

    return restrictedStocks[stockPublicId] || [];
}

const OpenIdeas = ({showCloseIdea, cancelCloseIdea, submitCloseIdea}) => {
    const {openIdeas, restrictedStocks, restrictedStocksAreStale, closeIdeaSubmissionStatus} = useSelector(
        ({restrictedStocks, restrictedStocksAreStale, management: {openIdeas, closeIdeaSubmissionStatus}}) => ({restrictedStocks, restrictedStocksAreStale, openIdeas, closeIdeaSubmissionStatus})
    );
    return (
        <div className="open-ideas">
            <MessageRenderer {...closeIdeaSubmissionStatus} />
            <table style={{width: "100%", border: "0px"}} className="open-ideas__table">
                {
                    openIdeas.map((idea, index) => {
                        return <OpenIdea key={index} {...idea}
                                         restrictions={restrictionsFor(restrictedStocks, idea.stockPublicId)}
                                         restrictionsAreStale={restrictedStocksAreStale}
                                         showCloseIdea={showCloseIdea}
                                         cancelCloseIdea={cancelCloseIdea}
                                         submitCloseIdea={submitCloseIdea}
                        />
                    })
                }
            </table>
        </div>
    );
};

OpenIdeas.propTypes = {
    showCloseIdea: PropTypes.func.isRequired,
    cancelCloseIdea: PropTypes.func.isRequired,
    submitCloseIdea: PropTypes.func.isRequired
};

export default OpenIdeas;
