import React, {useCallback} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CloseIdea from "./CloseIdea";
import {capitalize} from "../../textUtils";
import {percentageValueOf} from "../../numberUtils";
import * as dateHandler from "../dateHandler";
import RestrictedStock from "../../restrictedStocks/RestrictedStock";
import {IfFeature} from "@timgroup/common-javascript-components";
import {useWindowWidth} from "../../hooks/WindowWidth";

const OpenIdea = ({uuid, showingCloseIdea, showCloseIdea, cancelCloseIdea, submitCloseIdea, ticker, bucketIds, closeInProgress, restrictions, restrictionsAreStale, direction, companyName, performance, sent}) => {
    const windowWidth = useWindowWidth();
    const handleToggleCloseIdea = useCallback(() => {
        showCloseIdea(uuid);
    }, [showCloseIdea, uuid]);
    const handleCancelCloseIdea = useCallback(() => {
        cancelCloseIdea(uuid);
    }, [cancelCloseIdea, uuid]);
    const handleSubmitCloseIdea = useCallback(commentary => {
        submitCloseIdea(ticker, companyName, commentary, bucketIds, uuid);
    }, [submitCloseIdea, ticker, companyName, bucketIds, uuid]);

    const isOnPhone = windowWidth <= 480;
    const isRestricted = restrictions && restrictions.length > 0;

    const sent2 = isOnPhone ?
        <span>{ dateHandler.printShort(sent) }</span> :
        <span>{ dateHandler.printLong(sent) }</span>;

    const closeLabel = isOnPhone ? "Close" : "Close Idea";

    return (
        <tbody className={"open-ideas__open-idea " + (showingCloseIdea ? "open-ideas__open-idea--showing-close" : "")}>
        <tr className="open-ideas__open-idea-details">
            <td style={{width: "4em"}} className={`x-direction direction--${direction.toLowerCase()}`}>{ capitalize(direction) }</td>
            <td style={{width: "8em"}}>
                        <span className="x-ticker">
                            <span style={{verticalAlign: "middle", fontWeight: "bold", whiteSpace: "nowrap"}}>{ ticker }</span>
                            { isRestricted ? <RestrictedStock /> : undefined }
                        </span>
            </td>
            <td>
                <span className="x-company-name">{companyName}</span>
            </td>
            <td className="x-sent" style={{whiteSpace: "nowrap"}}>{ sent2 }</td>
            <IfFeature feature="showPerformance">
                {() => (
                    <>
                        <td className="x-performance-lifetime">{ percentageValueOf(performance.lifetime) }</td>
                        <td className="x-performance-today">{ percentageValueOf(performance.today) }</td>
                    </>
                )}
            </IfFeature>
            <td className="open-ideas__close-affordance">
                <button type="button"
                        className={classNames("x-show-close-idea", "open-ideas__close-link", "button-as-link")}
                        onClick={handleToggleCloseIdea}>{ closeLabel }</button>
            </td>
        </tr>
        {showingCloseIdea ? (
                <tr className="open-ideas__close-idea-details">
                    <td colSpan="100">
                        <CloseIdea closeInProgress={closeInProgress}
                                   onCancel={handleCancelCloseIdea}
                                   onClose={handleSubmitCloseIdea}
                                   restrictions={restrictions}
                                   restrictionsAreStale={restrictionsAreStale}/>
                    </td>
                </tr>
            ) : null}
        </tbody>
    );
};

export default OpenIdea;

OpenIdea.propTypes = {
    ticker: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    sent: PropTypes.string.isRequired,
    bucketIds: PropTypes.array.isRequired,
    cancelCloseIdea: PropTypes.func.isRequired,
    submitCloseIdea: PropTypes.func.isRequired
};
