import React, {useCallback} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ButtonWithinGroup = ({onChange, value, className, active, label}) => {
    const onClick = useCallback(() => onChange(value), [onChange, value]);
    return (
        <button key={value}
                type="button"
                className={classNames(active ? "active" : "", className)}
                data-test-locator={value}
                onClick={onClick}>
            {label}
        </button>
    )
};

const ButtonGroup = ({className, buttons, value, onChange}) => {
    return (
        <div className={classNames(className, "ButtonGroup")} style={{display: "inline-block"}}>
            {buttons.map(button => <ButtonWithinGroup key={button.value} onChange={onChange} value={button.value} className={button.className} label={button.label} active={value === button.value}/>)}
        </div>
    );
};

export default ButtonGroup;

ButtonGroup.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string
};
