import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import OpenIdeas from "./OpenIdeas.js";
import * as managementActions from "./../actions/managementActions.js";
import fetchOpenIdeasFromTIM from "../actions/fetchOpenIdeasFromTIM";
import submitCloseIdeaRequestToTIM from "../actions/submitCloseIdeaRequestToTIM";
import {useDeviceInfo} from "../../mobile/DeviceInfo";

const IdeaManagementComponent = ({fetchOpenIdeas = fetchOpenIdeasFromTIM, submitCloseIdeaRequest = submitCloseIdeaRequestToTIM}) => {
    const dispatch = useDispatch();
    const deviceInfo = useDeviceInfo();
    useEffect(() => {
        dispatch(managementActions.loadOpenIdeas(fetchOpenIdeas));
    }, []);

    function handleSubmitCloseIdea(ticker, companyName, commentary, bucketIds, ideaUUID) {
        dispatch(managementActions.submitCloseIdea(ticker, companyName, commentary, bucketIds, deviceInfo, ideaUUID, submitCloseIdeaRequest));
    }

    function handleCancelCloseIdea(ideaUUID) {
        dispatch(managementActions.cancelCloseIdea(ideaUUID));
    }

    function handleShowCloseIdea(ideaUUID) {
        dispatch(managementActions.showCloseIdea(ideaUUID));
    }

    return <OpenIdeas submitCloseIdea={handleSubmitCloseIdea}
                      cancelCloseIdea={handleCancelCloseIdea}
                      showCloseIdea={handleShowCloseIdea}/>
};

export default IdeaManagementComponent;
