import htmlparser from "htmlparser";
import * as R from "ramda";
import {observeAjax} from "../../authenticationSafeAjax";
import {htmlDecode} from "./../../distribution/htmlEnDeCode.js";

export function searchField(fieldName, fieldContents, element) {
    if (element[fieldName] && R.contains(fieldContents, element[fieldName].split(" "))) {
        return [element];
    }
    if (element.children) {
        return R.flatten(element.children.map(child => searchField(fieldName, fieldContents, child)));
    }
    return [];
}

export function transformSearchResults(xml) {
    const handler = new htmlparser.DefaultHandler(function (error, dom) {
        if (error) {
            console.log("Error: " + error);
        } else {
            // do nothing special
        }
    });

    const parser = new htmlparser.Parser(handler);
    parser.parseComplete(xml);

    return R.flatten(handler.dom.map(entry => searchField("name", "stock", entry)))
        .map(stock => stock.children ? {
            stockPublicId: htmlDecode(searchField("data", "publicId", stock)[0].children[0].data),
            stockSymbol: htmlDecode(searchField("data", "symbol", stock)[0].children[0].data),
            companyName: htmlDecode(searchField("data", "name", stock)[0].children[0].data)
        } : null)
        .filter(symbolObj => !!symbolObj);
}

export default function (stockSymbol) {
    return observeAjax({
        url: `/TradeIdeasMonitor/customservices/timui/stocks/search?query=${encodeURIComponent(stockSymbol.trim())}`,
        method: 'GET',
        headers: {Accept: "application/xml"}
    }).map(({data}) => transformSearchResults(data));
}
