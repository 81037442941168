import {v4 as uuidv4} from "uuid";
import * as R from "ramda";
import {combineReducers} from "redux";
import reduceReducers from "reduce-reducers";
import {appVersion as clientAppVersion} from "@timgroup/common-javascript-components";

import * as distributionActions from "./distribution/actions/distributionActions.js";
import * as tabBarActions from "./mobile/tabBar/tabBarActions.js";
import * as managementActions from "./management/actions/managementActions.js";
import * as authenticationActions from "./authentication/actions/authenticationActions";
import * as userInfoActions from "./userInfoActions";
import distributionReducers from "./distribution/distributionReducers";
import managementReducers from "./management/managementReducers";
import {restrictedStocks, restrictedStocksAreStale} from "./restrictedStocks/restrictedStocksReducers";

export function isUserInteraction(actionType) {
    switch (actionType) {
        case distributionActions.CHANGE_IDEA_ATTRIBUTES:
        case distributionActions.CHANGE_STOCK_SYMBOL:
        case distributionActions.INCLUDE_PORTFOLIO_FOR_IDEA:
        case distributionActions.INCLUDE_ALL_PORTFOLIOS_FOR_IDEA:
        case distributionActions.EXCLUDE_PORTFOLIOS_FROM_IDEA:
        case managementActions.START_CLOSE_IDEA:
        case managementActions.CANCEL_CLOSE_IDEA:
        case managementActions.TOGGLE_CLOSE_IDEA:
            return true;
        default:
            return false;
    }
}

function messages(state = { success: [], errors: [], warnings: [] }, action) {
    if (authenticationActions.TEMPORARY_PASSWORD_SENT === action.type) {
        return {
            errors: [],
            warnings: [],
            success: [
                "Thank you for your request. An email should be on its way to you. " +
                "If for any reason you do not receive it, please seek help from a system administrator. "
            ]
        };
    } else {
        return {
            errors: [],
            warnings: [],
            success: []
        };
    }
}

function changePasswordErrors(state = [], action) {
    if (action.type === authenticationActions.CHANGE_PASSWORD_ERRORS) {
        return action.payload;
    } else if (action.type === authenticationActions.PASSWORD_CHANGED) {
        return [];
    } else {
        return state;
    }
}

function hideTargetPrice(state = null, action) {
    if (action.type === userInfoActions.RECEIVED_USER_INFO) {
        return action.payload.hideTargetPrice;
    } else {
        return state;
    }
}

function showConflictOfInterest(state = null, action) {
    if (action.type === userInfoActions.RECEIVED_USER_INFO) {
        return action.payload.showConflictOfInterest;
    } else {
        return state;
    }
}

function defaultStopLossPercent(state = null, action) {
    if (action.type === userInfoActions.RECEIVED_USER_INFO) {
        return action.payload.defaultStopLossPercent ? action.payload.defaultStopLossPercent.toString() : null;
    } else {
        return state;
    }
}

function userCompanyPublicId(state = null, action) {
    switch (action.type) {
        case userInfoActions.RECEIVED_USER_INFO:
            return action.payload.companyPublicId;
        default:
            return state;
    }
}

function userInterface(state = { tabBar: { selected: "createIdea" } }, action) {
    switch (action.type) {
        case (tabBarActions.TAB_BAR_ITEM_SELECTED):
            return ({...state, tabBar: { selected: action.payload }});
        default:
            return state;
    }
}

function blockRestrictedStocks(state = false, action) {
    switch (action.type) {
        case userInfoActions.RECEIVED_USER_INFO:
            return action.payload.blockRestrictedStocks;
        default:
            return state;
    }
}

const mainReducers = combineReducers({
    clientAppVersion: (state = clientAppVersion, action) => state || null,
    showConflictOfInterest,
    distribution: distributionReducers,
    management: managementReducers,
    messages,
    restrictedStocks,
    restrictedStocksAreStale,
    changePasswordErrors,
    hideTargetPrice,
    defaultStopLossPercent,
    userCompanyPublicId,
    userInterface,
    blockRestrictedStocks
});

export function newEmptyStateTree() {
    return mainReducers(undefined, { type: "__GET_INITIAL_STATE" });
}

function resetOnSuccessfulDistribution(state, action) {
    switch (action.type) {
        case distributionActions.RECEIVE_SUCCESSFUL_IDEA_DISTRIBUTION_RESULTS:
            const { ticker, companyName, direction, bucketIds, successMessages, sent } = action.payload;
            const newState = newEmptyStateTree();

            // Copy over old state
            newState.clientAppVersion = state.clientAppVersion;

            newState.userCompanyPublicId = state.userCompanyPublicId;
            newState.defaultStopLossPercent = state.defaultStopLossPercent;
            newState.hideTargetPrice = state.hideTargetPrice;
            newState.showConflictOfInterest = state.showConflictOfInterest;

            newState.userInterface = state.userInterface;
            newState.changePasswordErrors = state.changePasswordErrors;

            newState.restrictedStocks = state.restrictedStocks;
            newState.restrictedStocksAreStale = state.restrictedStocksAreStale;

            newState.distribution.userDefaultedPortfolios = state.distribution.userDefaultedPortfolios;
            newState.distribution.defaultPortfoliosExcludedFromIdea = state.distribution.defaultPortfoliosExcludedFromIdea;
            newState.distribution.portfolios = state.distribution.portfolios;
            newState.distribution.portfoliosRequirements = state.distribution.portfoliosRequirements;

            // Reset some state to allow the next idea to be entered
            newState.distribution.ideaAttributes.stopLossAlert = state.defaultStopLossPercent || "";
            newState.distribution.portfoliosExcludedFromIdea = R.difference(state.distribution.defaultPortfoliosExcludedFromIdea, state.distribution.userDefaultedPortfolios);
            newState.distribution.submissionStatus = {
                success: successMessages,
                errors: []
            };
            newState.management.openIdeas = [{
                uuid: uuidv4(),
                showingCloseIdea: false,
                closeInProgress: false,
                ticker,
                companyName,
                direction,
                bucketIds,
                sent,
                performance: {
                    lifetime: 0.0,
                    today: 0.0
                }
            }].concat(state.management.openIdeas);

            return newState;
        default:
            return state;
    }
}

export default reduceReducers(resetOnSuccessfulDistribution, mainReducers);
