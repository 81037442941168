import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// courtesy analyticsdaemon: HandlebarsHelpers.js
function safeFormatLargeValue(value, decimals) {
    return !value ? "" :
        value >= Math.pow(10, 12) ? (value / Math.pow(10, 12)).toFixed(decimals) + "T" :
            value >= Math.pow(10, 9) ? (value / Math.pow(10, 9)).toFixed(decimals) + "B" :
                value >= Math.pow(10, 6) ? (value / Math.pow(10, 6)).toFixed(decimals) + "M" :
                    value >= Math.pow(10, 3) ? (value / Math.pow(10, 3)).toFixed(decimals) + "k" :
                    value.toFixed(decimals);
}

const InvestmentAmount = ({investmentAmount, currencyText}) => {
    const [highlight, setHighlight] = useState(false);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return () => {};
        }
        setHighlight(true);
        const timer = setTimeout(() => {
            setHighlight(false);
        }, 250);
        return () => {
            clearTimeout(timer);
        }
    }, [investmentAmount]);

    if (investmentAmount === null)
        return null;

    return <InvestmentAmountDisplay className="x-investment-amount" highlight={highlight}>{safeFormatLargeValue(investmentAmount, 1)} {currencyText}</InvestmentAmountDisplay>;
};

function noop() { }

const Portfolio = ({isIdeaCreationRequired, checked, portfolioValidationErrors, onCheckboxChange = noop, name, pricingMethod, investmentAmount, currency, id}) => {
    const handleCheckboxChange = useCallback(event => {
        onCheckboxChange({ checked: event.target.checked, portfolioId: id });
    }, [onCheckboxChange, id]);

    const included = isIdeaCreationRequired || checked;

    const sendIdeaToPortfolioCheckbox = (isIdeaCreationRequired || portfolioValidationErrors.length === 0)  ?
        <div>
            <input className="x-portfolio-checkbox"
                   type="checkbox"
                   checked={included}
                   onChange={handleCheckboxChange}
                   value={name}
                   disabled={isIdeaCreationRequired}
                   id={`portfolio-checkbox-${id}`}/>

            <label htmlFor={`portfolio-checkbox-${id}`} className="portfolio-mobile-checkbox" />
        </div>
        :  null;
    const currencyText = investmentAmount ? currency : "";

    let portfolioValidationErrorsDisplay;
    let portfolioRowTdStyle;

    if (portfolioValidationErrors.length > 0) {
        portfolioValidationErrorsDisplay =
            <tr>
                <td colSpan="4" style={{ paddingTop: 0 }}>
                    <ul className="portfolio-list__validation-errors">
                        {portfolioValidationErrors.map(message =>
                            <li key={message} className="x-portfolio-rule-validation-error portfolio-list__validation-error">{message}</li>
                        )}
                    </ul>
                </td>
            </tr>;

        portfolioRowTdStyle = portfolioValidationErrors.length > 0 ? { border: 0, paddingBottom: 0 } : {};
    }

    return (
        <tbody>
        <tr style={{ verticalAlign: "top" }} className={included ? "included" : "excluded"}>
            <td style={portfolioRowTdStyle} className="portfolio-list__select">{ sendIdeaToPortfolioCheckbox }</td>
            <td style={portfolioRowTdStyle} className="x-portfolio-name portfolio-list__name"><label htmlFor={`portfolio-checkbox-${id}`}>{name}</label></td>
            <td className="x-pricing-method portfolio-list__pricing-method"
                style={portfolioRowTdStyle}>
                {pricingMethod}
            </td>
            <td style={portfolioRowTdStyle} className="portfolio-list__investment-amount">
                <InvestmentAmount currencyText={currencyText} investmentAmount={investmentAmount} />
            </td>
        </tr>
        {portfolioValidationErrorsDisplay}
        </tbody>
    );
};

export default Portfolio;

Portfolio.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    portfolioValidationErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
    isIdeaCreationRequired: PropTypes.bool,
    investmentAmount: PropTypes.number,
    currency: PropTypes.string,
    onCheckboxChange: PropTypes.func
};

const InvestmentAmountDisplay = styled.span`
color: ${props => props.highlight ? "#00ACF0" : "inherit"};
`;
