import React, {useCallback, useState} from "react";
import classNames from "classnames";
import RestrictedStockCodes from "../../restrictedStocks/RestrictedStockCodes";
import SubmitButton from "../../common/components/SubmitButton";

const CloseIdea = ({restrictions, restrictionsAreStale, closeInProgress, onClose, onCancel}) => {
    const [closingCommentary, setClosingCommentary] = useState("");
    const handleClosingCommentaryChange = useCallback(event => {
        setClosingCommentary(event.target.value);
    }, []);
    const handleCloseIdea = useCallback(() => {
        onClose(closingCommentary);
    }, [onClose, closingCommentary]);

    const isRestricted = restrictions && restrictions.length > 0;

    return (
        <div className="pure-form">
            <label>Closing Commentary</label>
            <div><textarea value={closingCommentary}
                           autoFocus={true}
                           onChange={handleClosingCommentaryChange}/></div>
            {isRestricted ? <RestrictedStockCodes codes={restrictions}/> : undefined}
            <div style={{float: "right"}}>
                <SubmitButton className={classNames("x-close-idea-submit", "active", "open-ideas__close-button")}
                              onSubmit={handleCloseIdea}
                              disabled={restrictions === null || restrictionsAreStale === true}>
                    {closeInProgress ? "Closing..." : "Close Idea"}
                </SubmitButton>
                <button type="button"
                        className={classNames("x-close-idea-cancel", "button-as-link")}
                        onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default CloseIdea;
