import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import DesktopHome from "./DesktopHome";
import MobilePhoneHome from "../mobile/MobilePhoneHome";
import {logOpenScreen} from "../logEvent";
import * as restrictedStocksActions from "../restrictedStocks/restrictedStocksActions";
import * as userInfoActions from "../userInfoActions";
import environment from "../util/getEnvironment";
import {useDeviceInfo} from "../mobile/DeviceInfo";

const Home = ({}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        logOpenScreen("User opened the TIM Equities UI V2 screen.");
        dispatch(restrictedStocksActions.restrictedStocksInitialLoad());
        dispatch(userInfoActions.fetch());
    }, []);
    const deviceInfo = useDeviceInfo();

    const className = environment.TIM2 ? "TIM2" : "";
    return deviceInfo.isMobilePhone ? <MobilePhoneHome /> : <DesktopHome className={className}/>;
};

export default Home;
