export function classifyPortfolios(portfolios, portfolioRuleValidationErrors, portfoliosExcludedFromIdea = []) {
    const fixablePortfolioIdSet = new Set();
    const incompatiblePortfolioIdSet = new Set();
    const portfolioErrorsMultimap = {};
    portfolioRuleValidationErrors.forEach(err => {
        const portfolioId = err.forID;
        if (err.fixable)
            fixablePortfolioIdSet.add(portfolioId);
        else
            incompatiblePortfolioIdSet.add(portfolioId);
        if (!portfolioErrorsMultimap[portfolioId]) {
            portfolioErrorsMultimap[portfolioId] = [];
        }
        portfolioErrorsMultimap[portfolioId].push(err);
    });

    const selectedPortfolioIdSet = new Set();
    portfolios.forEach(p => {
        selectedPortfolioIdSet.add(p.id);
    });
    portfoliosExcludedFromIdea.forEach(portfolioId => {
        selectedPortfolioIdSet.delete(portfolioId);
    });

    const classify = portfolio => {
        const incompatible = incompatiblePortfolioIdSet.has(portfolio.id);
        const fixable = fixablePortfolioIdSet.has(portfolio.id);
        const mandatory = portfolio.isIdeaCreationRequired;
        if (incompatible) {
            return mandatory ? "mandatoryIncompatible" : "incompatible";
        }
        else if (fixable) {
            return mandatory ? "mandatoryFixable" : "fixable";
        }
        else {
            return "valid";
        }
    };

    return portfolios.map(portfolio => {
        return {
            selected: !!(portfolio.isIdeaCreationRequired || selectedPortfolioIdSet.has(portfolio.id)),
            classification: classify(portfolio),
            portfolio: portfolio,
            errors: portfolioErrorsMultimap[portfolio.id] || []
        };
    });
}

export function portfoliosWithPricingMethods({portfolios, portfoliosRequirements}) {
    return portfolios.map(portfolio => {
        const requirements = portfoliosRequirements.find(r => r.id === portfolio.id);
        return ({...portfolio, pricingMethod: requirements ? requirements.pricingMethod : ""});
    });
}
