import _ from "lodash";

import validatePortfolioRulesUsingTIM from "./validatePortfolioRulesUsingTIM.js";
import fetchPortfoliosRequirementsFromTIM from "./fetchPortfolioRequirementsFromTIM.js";
import postIdeaRequestToTIM from "./postIdeaRequestToTIM"
import directionFor from "./directionFor.js";
import timeHorizonFor from "./timeHorizonFor.js";
import convictionFor from "./convictionFor.js";
import {classifyPortfolios, portfoliosWithPricingMethods} from "./../portfolioSelectors.js";
import {log, logChangeIdeaAttributes, logChangePortfolioSelection, logCreateIdea} from "../../logEvent.js";
import validateIdeaAttributes from "./validateIdeaAttributes.js";
import environment from "../../util/getEnvironment.js";

export const CHANGE_IDEA_ATTRIBUTES = "CHANGE_IDEA_ATTRIBUTES";
export const CHANGE_STOCK_SYMBOL = "CHANGE_STOCK_SYMBOL";
export const INCLUDE_ALL_PORTFOLIOS_FOR_IDEA = "INCLUDE_ALL_PORTFOLIOS_FOR_IDEA";
export const INCLUDE_PORTFOLIO_FOR_IDEA = "INCLUDE_PORTFOLIO_FOR_IDEA";
export const EXCLUDE_PORTFOLIOS_FROM_IDEA = "EXCLUDE_PORTFOLIOS_FROM_IDEA";
export const RECEIVE_PORTFOLIOS = "RECEIVE_PORTFOLIOS";
export const REQUEST_VALIDATION = "REQUEST_VALIDATION";
export const ABORT_VALIDATION = "ABORT_VALIDATION";
export const RECEIVE_VALIDATION_RESULTS = "RECEIVE_VALIDATION_RESULTS";
export const CLEAR_VALIDATION_RESULTS = "CLEAR_VALIDATION_RESULTS";
export const REQUEST_IDEA_DISTRIBUTION = "REQUEST_IDEA_DISTRIBUTION";
export const RECEIVE_FAILED_IDEA_DISTRIBUTION_RESULTS = "RECEIVE_FAILED_IDEA_DISTRIBUTION_RESULTS";
export const RECEIVE_SUCCESSFUL_IDEA_DISTRIBUTION_RESULTS = "RECEIVE_SUCCESSFUL_IDEA_DISTRIBUTION_RESULTS";
export const RECEIVE_TICKER_QUERY_RESULTS = "RECEIVE_TICKER_QUERY_RESULTS";
export const RECEIVE_PORTFOLIOS_REQUIREMENTS_RESULTS = "RECEIVE_PORTFOLIOS_REQUIREMENTS_RESULTS";

const lazyLogCommentaryChanges = _.debounce(logChangeIdeaAttributes, 300);

export function changeIdeaAttributes(changedAttribute) {
    const logMessage = "Idea attribute changed: " + JSON.stringify(changedAttribute);
    if (changedAttribute.commentary === undefined) {
        logChangeIdeaAttributes(logMessage);
    } else {
        lazyLogCommentaryChanges(logMessage);
    }
    
    return {
        type: CHANGE_IDEA_ATTRIBUTES,
        payload: changedAttribute
    };
}

export function changeStockSymbol(stockPublicId, ticker, companyName) {
    logChangeIdeaAttributes(`Stock selected: ${companyName} (${ticker})`);
    return {
        type: CHANGE_STOCK_SYMBOL,
        payload: { ticker, companyName, stockPublicId }
    };
}

export function includePortfolioForIdea(portfolioId) {
    logChangePortfolioSelection("Portfolio selected: " + portfolioId);
    return {
        type: INCLUDE_PORTFOLIO_FOR_IDEA,
        payload: portfolioId
    };
}

export function includeAllPortfoliosForIdea() {
    logChangePortfolioSelection("All portfolios selected");
    return {
        type: INCLUDE_ALL_PORTFOLIOS_FOR_IDEA,
        payload: null
    };
}

export function excludePortfolioFromIdea(portfolioId) {
    logChangePortfolioSelection("Portfolio deselected: " + portfolioId);
    return _excludePortfolios([portfolioId]);
}

export function excludePortfoliosFromIdea(portfolioIds) {
    logChangePortfolioSelection("All portfolios deselected");
    return _excludePortfolios(portfolioIds);
}

function _excludePortfolios(portfolioIds) {
    return {
        type: EXCLUDE_PORTFOLIOS_FROM_IDEA,
        payload: portfolioIds
    };
}

export function receivePortfolios(portfolios) {
    return {
        type: RECEIVE_PORTFOLIOS,
        payload: portfolios
    };
}

export function loadPortfolios(fetchPortfolios) {
    return (dispatch) => {
        fetchPortfolios().subscribe(portfolios => {
            dispatch(receivePortfolios(portfolios));
            dispatch(loadPortfoliosRequirements());
        });
    };
}

export function requestValidation() {
    return {
        type: REQUEST_VALIDATION,
        payload: null
    }
}

export function abortValidation() {
    return {
        type: ABORT_VALIDATION,
        payload: null
    }
}

export function receiveValidationResults(response) {
    return {
        type: RECEIVE_VALIDATION_RESULTS,
        payload: response
    }
}

export function clearValidationState() {
    return {
        type: CLEAR_VALIDATION_RESULTS,
        payload: null
    }
}

export function requestIdeaDistribution() {
    return {
        type: REQUEST_IDEA_DISTRIBUTION,
        payload: null
    };
}

export function receiveFailedIdeaDistributionResults(messages) {
    return {
        type: RECEIVE_FAILED_IDEA_DISTRIBUTION_RESULTS,
        payload: messages
    };
}

export function receiveSuccessfulIdeaDistributionResults(successMessages, ticker, companyName, direction, bucketIds, sent) {
    return {
        type: RECEIVE_SUCCESSFUL_IDEA_DISTRIBUTION_RESULTS,
        payload: {
            successMessages, ticker, companyName, direction, bucketIds, sent
        }
    };
}

export function receivePortfoliosRequirementsQueryResults(portfoliosRequirements) {
    return {
        type: RECEIVE_PORTFOLIOS_REQUIREMENTS_RESULTS,
        payload: portfoliosRequirements
    };
}

export function showSupportingInformation() {
    return {
        type: "SHOW_SUPPORTING_INFORMATION"
    }
}

export function hideSupportingInformation() {
    return {
        type: "HIDE_SUPPORTING_INFORMATION"
    }
}

export function validateIdeaAttributesAgainstPortfolioRules(serverSideValidation = validatePortfolioRulesUsingTIM, clientSideValidation = validateIdeaAttributes) {
    return (dispatch, getState) => {
        const startTime = new Date();
        const { distribution } = getState();
        const ideaAttributes = distribution.ideaAttributes;
        const validationAttributes = {
            ticker: ideaAttributes.ticker,
            targetPrice: ideaAttributes.targetPrice,
            commentary: ideaAttributes.commentary,
            direction: directionFor(ideaAttributes.direction),
            stopLossAlert: ideaAttributes.stopLossAlert,
            timeHorizon: timeHorizonFor(ideaAttributes.timeHorizon),
            size: ideaAttributes.investmentSize,
            conviction: convictionFor(ideaAttributes.investmentSize)
        };

        if (validationAttributes.ticker) {
            const clientSideErrors = clientSideValidation(distribution.portfolios, validationAttributes);
            dispatch(receiveValidationResults({
                clientSideFailures: clientSideErrors
            }));

            dispatch(requestValidation());

            serverSideValidation(
                portfoliosWithPricingMethods(distribution),
                validationAttributes,
                serverSideResponse => {
                    dispatch(receiveValidationResults({
                        serverSideFailures: serverSideResponse.failures,
                        investments: serverSideResponse.portfolioInvestments || []
                    }));

                    const duration = new Date() - startTime;
                    log("ui-v2-validate-portfolio-rules",
                        `It took ${duration / 1000} secs to validate portfolio rules.`,
                        {duration_millis: duration});
                },
                () => {
                    dispatch(abortValidation());
                });
        } else {
            dispatch(clearValidationState());
        }
    };
}

export function logMessageFor(portfolios, portfoliosExcludedFromIdea, portfolioRuleValidationErrors, investmentSize, additionalRemarks) {
    function errorsFor(portfolioId){
        const errorList = portfolioRuleValidationErrors.filter(error => error.forID === portfolioId).map(e => e.message);
        return "[" + (errorList ? errorList.join(" / ") : "") + "]";
    }
    function unfixableErrorsFor(portfolioId){
        const errorList = portfolioRuleValidationErrors.filter(error => error.forID === portfolioId && !error.fixable).map(e => e.message);
        return "[" + (errorList ? errorList.join(" / ") : "") + "]";
    }

    function messageFor(tag, portfolioNames) {
        const count = portfolioNames.length;
        return count > 0 ? " " + tag + " (" + count + "): " + portfolioNames.join(", ") + "." : ""
    }

    const classifiedPortfolios = classifyPortfolios(portfolios, portfolioRuleValidationErrors, portfoliosExcludedFromIdea);
    const deselectedPortfolios = classifiedPortfolios
        .filter(cp => !cp.selected)
        .map(cp => cp.portfolio);
    const notValidPortfolios = classifiedPortfolios
        .filter(cp => (cp.classification === "fixable" || cp.classification === "mandatoryFixable"))
        .map(cp => cp.portfolio);
    const notCompatiblePortfolios = classifiedPortfolios
        .filter(cp => (cp.classification === "incompatible" || cp.classification === "mandatoryIncompatible"))
        .map(cp => cp.portfolio);

    const excludedPortfolioIds = classifiedPortfolios.filter(cp => !(cp.classification === "valid" && cp.selected)).map(cp => cp.portfolio.id);

    const portfolioCount = (portfolios.length - excludedPortfolioIds.length) + " (out of " + portfolios.length + ")";

    const disabled = messageFor("Disabled", deselectedPortfolios.map(portfolio => portfolio.name));
    const notValid = messageFor("Not valid", notValidPortfolios.map(portfolio => portfolio.name + " " + errorsFor(portfolio.id)));
    const notCompatible = messageFor("Not compatible", notCompatiblePortfolios.map(portfolio => portfolio.name + " " + unfixableErrorsFor(portfolio.id)));

    return "Sent to " + portfolioCount + " portfolios. Size: " + investmentSize + "." + disabled + notValid + notCompatible + " " + additionalRemarks;
}

export function distributeIdea(deviceInfo, postIdeaRequest = postIdeaRequestToTIM, log = logCreateIdea) {
    return (dispatch, getState) => {
        const {
            distribution: {
                ideaAttributes,
                ideaAttachments,
                portfolios,
                portfolioRuleValidationResult,
                portfoliosExcludedFromIdea
            }
        } = getState();
        const ideaAttributesToSubmit = {...ideaAttributes};
        delete ideaAttributesToSubmit.companyName;
        delete ideaAttributesToSubmit.stockPublicId;
        delete ideaAttributesToSubmit.typedTicker;
        ideaAttributesToSubmit.fromMobile = deviceInfo.isMobilePhone;
        ideaAttributesToSubmit.eventSource = environment.TIM2 ? "TIM2_CREATE_POPUP" : deviceInfo.isMobilePhone ? "MOBILE" : "SCREEN_UI_V2";
        ideaAttributesToSubmit.stopLossAlert = ideaAttributesToSubmit.stopLossAlert.replace(/[-%]/g, "");
        ideaAttributesToSubmit.attachmentIds = ideaAttachments.valueSeq().map(file => file.binaryDataId).filter(id => !!id).toJS();
        const submissionAttributes = {
            ideaAttributes: ideaAttributesToSubmit,
            portfoliosExcludedFromIdea,
            portfolioRuleValidationResult
        };

        dispatch(requestIdeaDistribution());

        const validPortfolios = classifyPortfolios(portfolios, portfolioRuleValidationResult.failures, portfoliosExcludedFromIdea)
                .filter(cp => cp.classification === "valid" && cp.selected)
                .map(cp => cp.portfolio);

        postIdeaRequest(validPortfolios, submissionAttributes).subscribe(messages => {
            if (messages.errors.length === 0) {
                const bucketIds = messages.bucketIds.map(({id}) => id);
                dispatch(receiveSuccessfulIdeaDistributionResults(messages.success,
                    ideaAttributes.ticker,
                    ideaAttributes.companyName,
                    ideaAttributes.direction,
                    bucketIds,
                    messages.sent));
                log(logMessageFor(portfolios, portfoliosExcludedFromIdea, portfolioRuleValidationResult.failures, ideaAttributes.investmentSize, "Successfully submitted the idea."));
            } else {
                dispatch(receiveFailedIdeaDistributionResults(messages));
                const failure = "Failed to submit the idea, reason: " + messages.errors.join(" / ");
                log(logMessageFor(portfolios, portfoliosExcludedFromIdea, portfolioRuleValidationResult.failures, ideaAttributes.investmentSize, failure));
            }
        });
    };
}

export function loadPortfoliosRequirements(fetchPortfoliosRequirements = fetchPortfoliosRequirementsFromTIM) {
    return (dispatch, getState) => {
        const { distribution: { portfolios } } = getState();

        function asyncMapPortfolio(portfolio) {
            return new Promise((resolve) => {
                fetchPortfoliosRequirements(portfolio.id).subscribe(resolve);
            });
        }

        Promise.all(portfolios.map(asyncMapPortfolio)).then(results => {
            dispatch(receivePortfoliosRequirementsQueryResults(results))
        });
    };
}
