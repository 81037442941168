import React, {useCallback, useRef} from "react";
import {useDropzone} from "react-dropzone";
import nanoajax from "nanoajax";
import Observable from "zen-observable";
import * as attachmentActions from "../actions/attachmentActions";
import {ValidationError} from "../actions/attachmentActions";
import closeIcon from "../../../images/x.svg";
import {useSubscriptionsHolder} from "../../hooks/SubscriptionHolder";
import {HttpError} from "@timgroup/common-javascript-components";

function uploadAttachment(file) {
    return new Observable(observer => {
        const formData = new FormData();
        formData.append("file", file);

        const request = {
            url: "/TradeIdeasMonitor/ui/api/attachment",
            method: "POST",
            headers: { "Accept": "application/json", "X-Requested-With": "XMLHttpRequest" },
            body: formData
        };

        const xhr = nanoajax.ajax(request, (code, responseText, xhr) => {
            const contentType = xhr.getResponseHeader("Content-Type");
            if (code === 200) {
                const data = JSON.parse(responseText);
                observer.next(data.id);
                observer.complete();
            }
            else if (code === 400 && contentType && contentType.toLowerCase().startsWith("text/plain")) {
                observer.error(new ValidationError(responseText));
            }
            else {
                observer.error(new HttpError(code, xhr));
            }
        });

        return () => {
            xhr.abort();
        }
    });
}

const UploadErrorMessage = ({uploadError, uploadState}) => {
    if (uploadState === "UPLOADED")
        return null;

    return (
        <div style={{width: 268, color: "#d0021b", marginTop: -8, marginBottom: 4}}>{uploadError.validationError ? uploadError.validationError : uploadError.toString()}</div>
    );
};

const UploadedAttachment = ({fileId, file: { filename, uploadState, uploadError }, dispatch}) => {
    const doRemove = useCallback(e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(attachmentActions.remove(fileId));
        return false;
    }, [fileId, dispatch]);

    return (
        <div>
            <div style={{position: "relative", width: 268, borderRadius: 13.5, backgroundColor: uploadState === "UPLOADED" ? "#e2dede" : "#d0969d", marginBottom: 10, paddingTop: 8, paddingBottom: 8, paddingLeft: 10, paddingRight: 28,
                            overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                {filename}
                <img style={{position: "absolute", right: 4, top: 2, cursor: "pointer"}} src={closeIcon} alt="Remove" width={24} height={24} onClick={doRemove}/>
            </div>
            <UploadErrorMessage uploadError={uploadError} uploadState={uploadState} />
        </div>
    );
};

const UploadedAttachments = ({ideaAttachments, dispatch}) => {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {ideaAttachments.entrySeq().filter(([, file]) => file.uploadState === "UPLOADED" || file.uploadState === "FAILED").map(([fileId, file]) => (
                <UploadedAttachment key={fileId} file={file} fileId={fileId} dispatch={dispatch}/>
            )).toJS()}
        </div>
    );
};

const IdleDropzone = ({isDragActive}) => {
    if (isDragActive) {
        return (
            <>
                <div style={{marginTop: 16}}>Drop document here to upload</div>
            </>
        );
    }
    else {
        return (
            <>
                <div style={{marginTop: 16}}>Drag document here to upload</div>
                <div style={{
                    backgroundColor: "#9b9b9b", color: "white", borderRadius: 14.5, cursor: "pointer",
                    marginTop: 4, marginLeft: 24, marginRight: 24,
                    paddingTop: 6, paddingBottom: 6
                }}>or select file
                </div>
            </>
        );
    }
};

const BusyDropzone = () => (
    <>
        <div style={{marginTop: 16}}>Uploading...</div>
        <div style={{marginTop: 4}} className="spinner">
            <div className="bounce1"/>
            <div className="bounce2"/>
            <div className="bounce3"/>
        </div>
    </>
);

const MAX_FILE_SIZE = 128 * 1024 * 1024;
const EXTENSIONS = [".txt", ".doc", ".docx", ".xls", ".xlsx", ".png", ".gif", ".jpg", ".jpeg", ".pdf"];

const Attachments = ({ideaAttachments, dispatch}) => {
    const uploadSubscriptions = useSubscriptionsHolder();
    const nextFileId = useRef(0);
    const onDropAccepted = useCallback(files => {
        for (const file of files) {
            const fileId = nextFileId.current++;
            if (file.size > MAX_FILE_SIZE) {
                dispatch(attachmentActions.fileRejected(fileId, file.name, "File must be less than 128Mb in size"));
                continue;
            }
            dispatch(attachmentActions.fileAdded(fileId, file.name));
            dispatch(attachmentActions.startingUpload(fileId));
            uploadSubscriptions(uploadAttachment(file).subscribe(
                binaryDataId => {
                    dispatch(attachmentActions.finishedUpload(fileId, binaryDataId));
                },
                err => {
                    dispatch(attachmentActions.failedToUpload(fileId, err));
                }
            ));
        }
    }, []);
    const onDropRejected = useCallback(files => {
        const extensionsRegexp = new RegExp("\\.(" + EXTENSIONS.map(str => str.substring(1)).join("|") + ")$", "i");
        for (const file of files) {
            const fileId = nextFileId.current++;
            if (!extensionsRegexp.exec(file.name)) {
                dispatch(attachmentActions.fileRejected(fileId, file.name, "File must end in one of: " + EXTENSIONS.join(", ")));
            }
        }
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDropAccepted, onDropRejected, accept: EXTENSIONS});

    nextFileId.current = Math.max(...ideaAttachments.keySeq().toJS(), 0) + 1;
    const uploading = !ideaAttachments.valueSeq().filter(file => file.uploadState === "PENDING" || file.uploadState === "TRANSFERRING").isEmpty();

    return (
        <div className="pure-control-group">
            <label htmlFor="attachments-dropzone-input" style={{verticalAlign: "top", marginTop: 6}}>Attachments</label>
            <div style={{display: "inline-block"}}>
                <UploadedAttachments ideaAttachments={ideaAttachments} dispatch={dispatch}/>
                <div {...getRootProps({id: "attachments-dropzone", style: {border: "solid 1px rgba(159, 167, 178, 0.37)", borderRadius: 2, position: "relative", width: 268, height: 89, padding: "7px 7px"}})}>
                    <div style={{border: "dashed 1px #cac9c9", borderRadius: 1, padding: 0, width: 252, height: 75, textAlign: "center", color: "#4a4a4a"}}>
                        <input {...getInputProps({id: "attachments-dropzone-input"})} />
                        {uploading ? <BusyDropzone/> : <IdleDropzone isDragActive={isDragActive}/>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Attachments;
