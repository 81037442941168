import ajax from "../../authenticationSafeAjax";
import TIMMessagesFrom from "../../TIMMessagesFrom.js";

export function authenticate(username, password, onSuccess, onError, onPasswordReset) {
    ajax({
        url:`/TradeIdeasMonitor/j_security_check`,
        method: 'POST',
        contentType: "application/x-www-form-urlencoded",
        body: `j_username=${encodeURIComponent(username)}&j_password=${encodeURIComponent(password)}&expireCookieAfter24hrs`,
    }, (code, response) => {
        const messages = TIMMessagesFrom(response);
        const hasPasswordReset = response.indexOf("Please choose a new password and enter it twice below") !== -1;

        if (hasPasswordReset) {
            onPasswordReset();
        } else if (messages.errors.length === 0) {
            onSuccess();
        } else {
            onError();
        }
    });
}

export function requestSessionStatus(callback) {
    ajax({url:"/TradeIdeasMonitor/ui/api/sessionStatus"}, (code, response) => {
        const { signedOn: isSignedOn } = response;

        callback(isSignedOn);
    });
}

