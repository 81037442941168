import React, {useCallback, useEffect, useState} from "react";
import {useWindowWidth} from "../../hooks/WindowWidth";

const colors = {
    grayLight: '#aaa',
    basicBorderColor: '#ccc',
    white: '#fff'
};

const infoTip = {
    display: 'inline-block',
    marginLeft: '5px',
    position: 'relative'
};

const infoTipTriangle = {
    height: '10px',
    left: '0',
    position: 'absolute',
    top: '8px',
    width: '0',
    zIndex: '1'
};

const leftTriangleBeforeAfter = {
    borderBottom: '9px solid transparent',
    borderTop: '9px solid transparent',
    height: '0',
    position: 'absolute',
    top: '6px',
    width: '0'
};


const wideScreenStyles = {
    infoTipContainer: {
        position: 'absolute',
        top: '-6px',
        left: '31px',
        zIndex: '1000'
    },

    infoTipTriangle: infoTipTriangle,

    beforeTriangle: {
        ...leftTriangleBeforeAfter,
        borderRight: '9px solid #bbb',
        right: '0'
    },
    afterTriangle: {
        ...leftTriangleBeforeAfter,
        borderRight: `9px solid ${colors.white}`,
        right: '-1px'
    }};



const topTriangleBeforeAfter = {
    borderLeft: '9px solid transparent',
    borderRight: '9px solid transparent',
    height: '0',
    position: 'absolute',
    right: '-223px',
    width: '0'
};



const narrowScreenStyles = {
    infoTipContainer: {
        position: 'absolute',
        'top': '32px',
        left: '-199px',
        zIndex: '1000'
    },
    infoTipTriangle: infoTipTriangle,

    beforeTriangle: {
        ...topTriangleBeforeAfter,
        borderBottom: '9px solid #bbb',
        top: '-16px'

    },
    afterTriangle: {
        ...topTriangleBeforeAfter,
        borderBottom: `9px solid ${colors.white}`,
        top: '-15px'
    }
};

const infoTipContentContainer = {
    border: `1px solid ${colors.basicBorderColor}`,
    borderRadius: '4px',
    boxShadow: `0 1px 3px ${colors.basicBorderColor}`,
    borderBottom: `1px solid ${colors.grayLight}`,
    background: colors.white,
    padding: '5px 10px',
    width: '240px'
};

const BlueInfoTip = ({outside = document, className, children, onOpen, onClose}) => {
    const [isOpen, setOpen] = useState(false);
    const windowWidth = useWindowWidth();
    const handleClickInside = useCallback(() => {
        setOpen(true);
    }, []);
    useEffect(() => {
        function handleClickOutside() {
            setOpen(false);
            onClose();
        }
        if (isOpen) {
            onOpen();
            outside.addEventListener("click", handleClickOutside);
        }
        return () => {
            outside.removeEventListener("click", handleClickOutside);
        };
    }, [isOpen]);
    const isNarrowScreen = windowWidth <= 700;

    const styles = isNarrowScreen ? narrowScreenStyles : wideScreenStyles;

    return (
        <div style={infoTip} className={className}>
            <div className="x-question-mark" style={{padding: "0.45em", cursor: "help"}} onClick={handleClickInside}>
                <div className="question-mark">?</div>
            </div>
            <div style={{...styles.infoTipContainer, display: isOpen ? "block" : "none"}}>
                <div style={styles.infoTipTriangle}><span style={styles.beforeTriangle}/><span style={styles.afterTriangle}/></div>
                <div style={infoTipContentContainer}> {children} </div>
            </div>
        </div>
    );
};

export default BlueInfoTip;
