import React from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../../common/components/ButtonGroup";

const ConflictOfInterest = ({ conflictOfInterest, onChange, requiredClass, descriptionRequiredClass }) => {
    let buttonGroupValue;
    if (conflictOfInterest) {
        buttonGroupValue = conflictOfInterest.exists ? "yes" : "no";
    }
    else {
        buttonGroupValue = null;
    }
    const description = buttonGroupValue === "yes" ? conflictOfInterest.description : "";

    return (
        <div className={"pure-control-group " + requiredClass}>
            <label style={{verticalAlign: "top"}}>Conflict of Interest</label>
            <div style={{display: "inline-block", width: "24em", verticalAlign: "top"}}>
                <div style={{
                    color: requiredClass === "idea-attribute--required" ? null : "#888",
                    marginBottom: "0.5em"
                }}>
                    Do you, your group or anyone associated with you have any conflict of interest regarding this
                    recommendation?
                </div>
                <ButtonGroup className="x-conflictOfInterest-group"
                             onChange={(value) => onChange({
                                 exists: value === "yes",
                                 description: conflictOfInterest && value === "yes" ? conflictOfInterest.description : ""
                             })}
                             value={buttonGroupValue}
                             buttons={[
                                 {label: "No", value: "no", className: "x-conflict-no idea-attribute__input"},
                                 {label: "Yes", value: "yes", className: "x-conflict-yes idea-attribute__input"}
                             ]}/>
                <div className={descriptionRequiredClass}>
                    <input value={ description }
                           disabled={ buttonGroupValue !== "yes" }
                           placeholder={ buttonGroupValue === "yes" ? "Description (not sent to client)" : "" }
                           className="x-conflict-description idea-attribute__input"
                           style={{width: "230px"}}
                           type="text"
                           onChange={(e) => {
                               if (buttonGroupValue === "yes") {
                                   onChange({
                                       exists: true,
                                       description: e.currentTarget.value
                                   })
                               }
                           } }/>
                </div>
            </div>
        </div>
    );
};

ConflictOfInterest.propTypes = {
    conflictOfInterest: PropTypes.shape({
        exists: PropTypes.bool.isRequired,
        description: PropTypes.string.isRequired
    }),
    onChange: PropTypes.func.isRequired,
    requiredClass: PropTypes.string.isRequired,
    descriptionRequiredClass: PropTypes.string.isRequired
};

export default ConflictOfInterest;
