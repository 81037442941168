import ajax from "../authenticationSafeAjax";
import {log} from "../logEvent.js";

const RESTRICTED_STOCKS_POLL_INTERVAL = 1000 * 60 * 2;
const RESTRICTED_STOCK_MAX_REQUEST_TIME = 2000;
export const RECEIVED_RESTRICTED_STOCKS = "RECEIVED_RESTRICTED_STOCKS";
export const RESTRICTED_STOCKS_STALE = "RESTRICTED_STOCKS_STALE";

export function receivedRestrictedStocks(restrictedStocks) {
    return {
        type: RECEIVED_RESTRICTED_STOCKS,
        payload: restrictedStocks
    }
}

export function restrictedStocksStale() {
    return {
        type: RESTRICTED_STOCKS_STALE
    }
}

export function restrictedStocksInitialLoad(callback = requestRestrictedStocks) {
    return (dispatch) => {
        callback(dispatch);

        setInterval(() => callback(dispatch), RESTRICTED_STOCKS_POLL_INTERVAL);
    }
}

export function requestRestrictedStocks(dispatch) {
    const slowRequestTimer = setTimeout(() => dispatch(restrictedStocksStale()), RESTRICTED_STOCK_MAX_REQUEST_TIME);
    const startTime = Date.now();

    ajax({
        url: "/TradeIdeasMonitor/ui/api/restrictedStocks",
        method: 'GET',
        complete: (xhr, code) => {
            const timeTaken = Date.now() - startTime;
            log("ui-v2-request-restricted-stocks", `Requesting restricted stocks took ${timeTaken} ms`,
                {duration_millis: timeTaken, response_code: code});

        }
    }, function success(code, response) {
        clearTimeout(slowRequestTimer);
        dispatch(receivedRestrictedStocks(response));
    });
}
