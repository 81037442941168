import * as consts from "./../constants.js";

export default function(investmentSize) {
    if (investmentSize === consts.INVESTMENT_SIZE_LARGE) {
        return "A";
    }
    if (investmentSize === consts.INVESTMENT_SIZE_MEDIUM) {
        return "B";
    }
    if (investmentSize === consts.INVESTMENT_SIZE_SMALL) {
        return "C";
    }
    return null;
}
