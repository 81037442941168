import React, {useCallback, useState} from "react";
import {useDeviceInfo} from "../DeviceInfo";
import {AddToHomescreenBanner} from "./AddToHomescreenBanner";

const INSTRUCTIONS = {
    iPhone: (
        <div className="add-to-homescreen__instructions add-to-homescreen__instructions--iphone x-add-to-homescreen-instructions">
            <div className="add-to-homescreen__text">
                Tap the <span className="add-to-homescreen__icon--iphone"/> button below, and choose
                <br /> "Add to Home Screen"
            </div>
            <div className="add-to-homescreen__arrow">↓</div>
        </div>
    ),

    iPad: (
        <div className="add-to-homescreen__instructions add-to-homescreen__instructions--ipad x-add-to-homescreen-instructions">
            <div className="add-to-homescreen__text">
                Tap the <span className="add-to-homescreen__icon--iphone"/> button, and choose
                <br /> "Add to Home Screen"
            </div>
        </div>
    ),

    android: (
        <div className="add-to-homescreen__instructions add-to-homescreen__instructions--android x-add-to-homescreen-instructions">
            <div className="add-to-homescreen__text">
                Tap the <span className="add-to-homescreen__icon--android"/> button, and choose<br /> "Add to Home screen"
            </div>
        </div>
    )
};

function flavourForDevice(deviceInfo) {
    if (!deviceInfo.isUsingTimMobile)
        return null;
    if (deviceInfo.isIPhone && !deviceInfo.isIOSStandalone)
        return "iPhone";
    if (deviceInfo.isIPad && !deviceInfo.isIOSStandalone)
        return "iPad";
    if (deviceInfo.isAndroid && !deviceInfo.isAndroidStandalone)
        return "android";
    return null;
}

const AddToHomescreen = ({}) => {
    const [expanded, setExpanded] = useState(false);
    const deviceInfo = useDeviceInfo();
    const toggle = useCallback(() => setExpanded(s => !s), []);

    const flavour = flavourForDevice(deviceInfo);
    if (!flavour)
        return <div/>;
    else
        return <AddToHomescreenBanner onClickBanner={toggle}
                                      isExpanded={expanded}
                                      instructions={INSTRUCTIONS[flavour]}/>;
};

export default AddToHomescreen;
