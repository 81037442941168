import Observable from "zen-observable";
import microajax from "./util/microajax";
import {createHashHistory} from "history";
import {HttpError} from "@timgroup/common-javascript-components";

const noop = () => {};

export function isHttpError(statusCode) {
    return statusCode >= 400 && statusCode <= 599;
}

export function isNetworkError(statusCode) {
    return statusCode === 0;
}

function authenticationSafeAjax({url, method, headers, body, contentType, error = noop, complete = noop},
          callback = noop, historyProvider = createHashHistory) {
    return microajax({
        url,
        method,
        headers,
        data: body,
        contentType,
        success: (data, code, xhr) => {
            callback(code, data, xhr);
        },
        error: (xhr, code) => {
            if (code === 401) {
                historyProvider().push("/sign-in");
            } else {
                error(xhr, code);
            }

        },
        complete
    });
}

export function observeAjax({url, method, headers, body, contentType},
                            historyProvider = createHashHistory) {
    return new Observable(observer => {
        const request = authenticationSafeAjax({
            url,
            method,
            headers,
            body,
            contentType,
            error(xhr, code) {
                observer.error(new HttpError(code, xhr))
            }
        }, (code, data, xhr) => {
            observer.next({code, data, xhr});
            observer.complete();
        }, historyProvider);
        return () => {
            request.abort();
        }
    });
}

export default authenticationSafeAjax;
