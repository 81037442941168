import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import thunkMiddleware from "redux-thunk";
import {applyMiddleware, compose, createStore} from "redux";
import {Provider} from "react-redux";
import persistState from "redux-localstorage";
import {HashRouter} from "react-router-dom";

import reducers from "./reducers";
import Root from "./Root";
import DeviceInfo, {DeviceInfoContext} from "./mobile/DeviceInfo";
import mergeStateTree from "./mergeStateTree";

import {FeaturesContext} from "@timgroup/common-javascript-components";
import {makeFeatures} from "./common/featureFlags";

import favicon from "../images/acuris-equities-favicon.ico";

import "./globalErrorHandler";
import "../stylesheets/pure.css";
import "../stylesheets/grids-responsive.css";
import "../stylesheets/react-autosuggest.css";
import "../stylesheets/custom.less";

const middlewares = [thunkMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = composeEnhancers(applyMiddleware(...middlewares));

if (new DeviceInfo().isIOSStandalone) {
    middleware = compose(
        persistState(undefined, {
            merge: mergeStateTree
        }),
        middleware
    );
} 

const store = createStore(reducers, middleware);

const mountElt = document.getElementById("root");

// Set favicon
const link = document.createElement('link');
link.type = 'image/x-icon';
link.rel = 'shortcut icon';
link.href = favicon;
document.getElementsByTagName('head')[0].appendChild(link);

const features = makeFeatures();
const deviceInfo = new DeviceInfo();

if (process.env.NODE_ENV !== "production" && module.hot) {
    console.log("Enabling hot-reload of React componentry");
    module.hot.accept("./Root", () => {
        const newRootModule = require("./Root");
        const NewRoot = newRootModule["default"];
        ReactDOM.unmountComponentAtNode(mountElt);
        ReactDOM.render(
            <Provider store={store}>
                <DeviceInfoContext.Provider value={deviceInfo}>
                    <FeaturesContext.Provider value={features}>
                        <HashRouter>
                            <NewRoot />
                        </HashRouter>
                    </FeaturesContext.Provider>
                </DeviceInfoContext.Provider>
            </Provider>
            , mountElt);
    });
}

ReactDOM.render(
    <Provider store={store}>
        <DeviceInfoContext.Provider value={deviceInfo}>
            <FeaturesContext.Provider value={features}>
                <HashRouter>
                    <Root />
                </HashRouter>
            </FeaturesContext.Provider>
        </DeviceInfoContext.Provider>
    </Provider>
    , mountElt);
