import Immutable from "immutable";

export class ValidationError extends Error {
    constructor(message) {
        super(`Upload failed: ${message}`);
        this.validationError = message;
    }
}

export function fileAdded(fileId, filename) {
    return {
        type: "ATTACHMENTS/FILE_ADDED",
        payload: {
            fileId,
            filename
        }
    }
}

export function fileRejected(fileId, filename, reason) {
    return {
        type: "ATTACHMENTS/FILE_REJECTED",
        payload: {
            fileId,
            filename,
            reason
        }
    }
}

export function startingUpload(fileId) {
    return {
        type: "ATTACHMENTS/UPLOAD_STARTING",
        payload: {
            fileId
        }
    }
}

export function finishedUpload(fileId, binaryDataId) {
    return {
        type: "ATTACHMENTS/UPLOAD_COMPLETED",
        payload: {
            fileId,
            binaryDataId
        }
    }
}

export function failedToUpload(fileId, error) {
    return {
        type: "ATTACHMENTS/UPLOAD_FAILED",
        payload: {
            fileId,
            error
        },
        error: true
    }
}

export function remove(fileId) {
    return {
        type: "ATTACHMENTS/FILE_REMOVED",
        payload: {
            fileId
        }
    }
}

const DEFAULT_IDEA_ATTACHMENTS = { filename: null, uploadState: "PENDING", binaryDataId: null, uploadError: null };

function reduceFile(state = DEFAULT_IDEA_ATTACHMENTS, { type, payload }) {
    switch (type) {
        case "ATTACHMENTS/FILE_ADDED":
            return { ...state, filename: payload.filename, uploadState: "PENDING", binaryDataId: null, uploadError: null };
        case "ATTACHMENTS/FILE_REJECTED":
            return { ...state, filename: payload.filename, uploadState: "FAILED", binaryDataId: null, uploadError: new ValidationError(payload.reason) };
        case "ATTACHMENTS/UPLOAD_STARTING":
            return { ...state, uploadState: "TRANSFERRING" };
        case "ATTACHMENTS/UPLOAD_COMPLETED":
            return { ...state, uploadState: "UPLOADED", binaryDataId: payload.binaryDataId };
        case "ATTACHMENTS/UPLOAD_FAILED":
            return { ...state, uploadState: "FAILED", uploadError: payload.error };
        case "ATTACHMENTS/FILE_REMOVED":
            return DEFAULT_IDEA_ATTACHMENTS;
        default:
            return state;
    }
}

export function reducer(state = Immutable.Map(), action = undefined) {
    if (action.payload && action.payload.fileId) {
        const fileId = action.payload.fileId;
        if (action.type === "ATTACHMENTS/FILE_REMOVED") {
            return state.remove(fileId);
        }
        else {
            return state.update(fileId, fileState => reduceFile(fileState, action));
        }
    }
    else {
        return state;
    }
}
