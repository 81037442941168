import React, {useCallback} from "react";
import styled from "styled-components";
import WebLink from "./WebLink";
import Attachments from "./Attachments";
import * as distributionActions from "../actions/distributionActions";

const ToggleDown = styled.div`
display: inline-block;
width: 0;
height: 0;
border: solid 7px;
border-color: transparent transparent #4a4a4a transparent;
transform: rotate(${({enabled}) => enabled ? 180 : 0}deg) translateY(${({enabled}) => enabled ? -4 : -2}px);
cursor: pointer;
`;

const Label = ({visible, onClick}) => (
    <div onClick={onClick}><label htmlFor="supporting-information-toggle">Supporting Information</label> <ToggleDown id="supporting-information-toggle" enabled={visible}/></div>
);

const SupportingInformation = ({visible = true, ideaAttachments, webLink, dispatch}) => {
    const onWeblinkChange = useCallback(value => {
        dispatch(distributionActions.changeIdeaAttributes({webLink: value}));
    }, []);

    const toggle = useCallback(e => {
        if (visible) {
            dispatch(distributionActions.hideSupportingInformation());
        }
        else {
            dispatch(distributionActions.showSupportingInformation());
        }
    }, [visible]);

    const content = (
        <>
            <WebLink value={webLink} onChange={onWeblinkChange} requiredClass="idea-attribute" />
            <Attachments ideaAttachments={ideaAttachments} dispatch={dispatch}/>
        </>
    );

    return (
        <>
            <Label visible={visible} onClick={toggle}/>
            {visible ? content : null}
            <div style={{marginBottom: 16}} />
        </>
    )
};

export default SupportingInformation;
