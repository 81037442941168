import React from "react";
import MessageRenderer from "./../common/components/MessageRenderer";

const companyPublicIdsWithRestrictedStocks = [
    "4028ae830ff576d101100d3d1dcb5126", // KeyBanc
    "4028ae8e23420107012358a85d7316a6", // Barclays Capital
    "5501cb32113c5baa011150de01bf566b", // Societe Generale
    "8aec8b6900bde2ca0100cc172d71001f", // Merrill Lynch
    "8aec8b6900cd74ac0100d1ae543503b4", // Credit Suisse
    "8aec8b6900cd74ac0100d24357e2050b", // Gaia Investments - for testing!
    "8aec8b69016d0f3c010171442f4b7150"  // Citigroup
];

export const StaleRestrictedStocksWarning = (props) => {
    if (props.restrictedStocksAreStale && companyPublicIdsWithRestrictedStocks.includes(props.companyPublicId)) {
        return (
            <MessageRenderer errors={[
                    `Apologies, we're having trouble loading your stock restrictions.
                    You won't be able to create or close ideas for now.
                    Please wait while we fix this.`
            ]}/>
        );
    } else {
        return <div/>;
    }
};

export default StaleRestrictedStocksWarning