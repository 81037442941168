import { DIRECTION_LONG, DIRECTION_SHORT } from "../../common/constants.js";

export default function directionFor(direction) {
    if (direction === DIRECTION_LONG){
        return "L";
    }
    if (direction === DIRECTION_SHORT){
        return "S";
    }
}
