// borrowed from http://stackoverflow.com/questions/3700326/decode-amp-back-to-in-javascript

let charToEntityRegex;
let entityToCharRegex;
const charToEntity = {};
const entityToChar = {};

(function() {

    // add the default set
    const newEntities = {
        '&amp;'     :   '&',
        '&gt;'      :   '>',
        '&lt;'      :   '<',
        '&quot;'    :   '"',
        '&#39;'     :   "'"
    };

    const charKeys = [],
        entityKeys = [];
    for (const [key, echar] of Object.entries(newEntities)) {
        entityToChar[key] = echar;
        charToEntity[echar] = key;
        charKeys.push(echar);
        entityKeys.push(key);
    }
    charToEntityRegex = new RegExp('(' + charKeys.join('|') + ')', 'g');
    entityToCharRegex = new RegExp('(' + entityKeys.join('|') + '|&#[0-9]{1,5};' + ')', 'g');
})();

export function htmlEncode(value){
    return (!value) ? value : String(value).replace(charToEntityRegex, (match, capture) => charToEntity[capture]);
}

export function htmlDecode(value) {
    return (!value) ? value : String(value).replace(entityToCharRegex, (match, capture) => (capture in entityToChar) ? entityToChar[capture] : String.fromCharCode(parseInt(capture.substr(2), 10)));
}
