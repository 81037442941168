import * as R from "ramda";
import ajax from "../../authenticationSafeAjax";

export default function (commentary, bucketIds, deviceInfo, callback) {
    const source = deviceInfo.isMobilePhone ? "MOBILE" : "SCREEN_UI_V2";
    ajax({
            body: {commentary, bucketIds, source},
            headers: {"Content-Type": "application/json"},
            url: "/TradeIdeasMonitor/ui/api/closeIdeas"
        },
        (code, {errors, bucketIdsRemainingOpen}) => {
            return callback({
                errors: R.uniq(errors),
                bucketIdsRemainingOpen: bucketIdsRemainingOpen
            });
        }
    );
}
