export function inputFieldIsRequired(field, compatiblePortfolios) {
    return !!compatiblePortfolios.find(cp => cp.portfolio[field]);
}

export function targetPriceIsRequired(compatiblePortfolios) {
    return inputFieldIsRequired("isTargetPriceRequired", compatiblePortfolios);
}

export function timeHorizonIsRequired(compatiblePortfolios) {
    return inputFieldIsRequired("isTimeHorizonRequired", compatiblePortfolios);
}

export function commentaryIsRequired(compatiblePortfolios) {
    return inputFieldIsRequired("isCommentaryRequired", compatiblePortfolios);
}

export function minCommentaryRuleIsViolated(compatiblePortfolios) {
    return compatiblePortfolios.find(cp => cp.errors.find(error => error.message.startsWith("Min commentary (words) Hard limit:")));
}
