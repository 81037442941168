import React, {useCallback, useState} from "react";
import Observable from "zen-observable";
import PropTypes from "prop-types";
import Downshift from "downshift";
import searchForStockUsingTIM from "./../actions/searchForStockUsingTIM";
import RestrictedStock from "../../restrictedStocks/RestrictedStock";
import {useDispatch, useSelector} from "react-redux";
import {CHANGE_IDEA_ATTRIBUTES} from "../../distribution/actions/distributionActions";
import {useSingleSubscriptionHolder} from "../../hooks/SubscriptionHolder";

function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function highlightSearchText(text, input) {
    const regex = new RegExp('\\b' + escapeRegexCharacters(input), "i");
    const firstMatchIndex = text.search(regex);
    if (firstMatchIndex < 0) {
        return text;
    }
    return (
        <>
            {text.slice(0, firstMatchIndex)}
            <strong>{text.slice(firstMatchIndex, firstMatchIndex + input.length)}</strong>
            {text.slice(firstMatchIndex + input.length)}
        </>
    );
}

const Suggestion = ({suggestion: {stockSymbol, companyName, stockPublicId}, isRestricted, typedTicker}) => (
    <span>
        {highlightSearchText(stockSymbol, typedTicker)}
        {isRestricted(stockPublicId) ? <RestrictedStock/> : null}
        <br/>
        <small style={{color: "#777"}}>
            {highlightSearchText(companyName, typedTicker)}
        </small>
    </span>
);

function suggestionToString(suggestion) {
    if (!suggestion)
        return "";
    return suggestion.stockSymbol;
}

const StockSearch = ({onStockSymbolChange, queryTickerSelection = searchForStockUsingTIM, restrictedStocks = {}}) => {
    const typedTicker = useSelector(state => state.distribution.ideaAttributes.typedTicker);
    const dispatch = useDispatch();
    const [suggestions, setSuggestions] = useState([]);
    const registerTickerSearch = useSingleSubscriptionHolder();
    const onSuggestionSelected = useCallback((selection) => {
        if (selection) {
            const {stockPublicId, stockSymbol, companyName} = selection;
            onStockSymbolChange(stockPublicId, stockSymbol, companyName);
        }
        else {
            onStockSymbolChange("", "", "");
        }
    }, [onStockSymbolChange]);
    const onInputValueChange = useCallback((inputValue, stateAndHelpers) => {
        dispatch({
            type: CHANGE_IDEA_ATTRIBUTES,
            payload: {typedTicker: inputValue}
        });
        if (inputValue.length === 0) {
            registerTickerSearch(Observable.of().subscribe(x => {}));
            setSuggestions([]);
        }
        else {
            registerTickerSearch(queryTickerSelection(inputValue).subscribe(
                newSuggestions => {
                    setSuggestions(newSuggestions);
                }
            ));
        }
    }, [dispatch]);
    const isRestricted = useCallback(stockPublicId => {
        if (restrictedStocks === null)
            return false;
        const restrictions = restrictedStocks[stockPublicId];
        return restrictions && restrictions.length > 0;
    }, [restrictedStocks]);

    function handleInputBlur(event, selectItem) {
        const [exactMatch] = suggestions.filter(it => it.stockSymbol.toLowerCase() === event.target.value.toLowerCase());
        if (exactMatch) {
            selectItem(exactMatch);
        }
        else {
            selectItem(null);
        }
    }

    return (
        <Downshift onSelect={onSuggestionSelected} inputValue={typedTicker} onInputValueChange={onInputValueChange} itemToString={suggestionToString}>
            {({getInputProps, getLabelProps, getItemProps, getMenuProps, isOpen, highlightedIndex, selectItem}) => (
                <div className="pure-control-group">
                    <label htmlFor="stock" {...getLabelProps()}>Ticker</label>
                    <div className={`react-autosuggest__container ${isOpen ? "react-autosuggest__container--open" : ""}`}>
                        <input className="x-ticker-input" {...getInputProps({onBlur: event => handleInputBlur(event, selectItem), style: {width: 265}})}/>
                        { isOpen && suggestions.length > 0 ? (
                            <div className={`react-autosuggest__suggestions-container ${isOpen ? "react-autosuggest__suggestions-container--open" : ""}`}>
                                <ul {...getMenuProps()} className="react-autosuggest__suggestions-list">{suggestions.map((item, index) => (
                                    <li className={`react-autosuggest__suggestion ${index === 0 ? "react-autosuggest__suggestion--first" : ""} ${index === highlightedIndex ? "react-autosuggest__suggestion--highlighted" : ""}`}
                                        {...getItemProps({key: item.stockPublicId, index, item})}>
                                        <Suggestion suggestion={item} isRestricted={isRestricted} typedTicker={typedTicker}/>
                                    </li>
                                ))}</ul>
                            </div>
                        ) : null }
                    </div>
                </div>
            )}
        </Downshift>
    )
};

export default StockSearch;

StockSearch.propTypes = {
    onStockSymbolChange: PropTypes.func,
    queryTickerSelection: PropTypes.func,
    restrictedStocks: PropTypes.object
};
