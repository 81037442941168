import React from "react";
import {useSelector} from "react-redux";

import IdeaDistribution from "../distribution/components/IdeaDistribution";
import IdeaManagement from "../management/components/IdeaManagement";
import StaleRestrictedStocksWarning from "./StaleRestrictedStocksWarning";
import environment from "../util/getEnvironment";

export const boxShadowColour = "#F0ECE9";
export const boxBorderColour =  "#F0ECE9";

const sectionStyle = environment.TIM2 ? {
    borderRadius: "4px",
    boxShadow: `${boxShadowColour} 0px 2px 1px 0px`,
    border: `1px solid ${boxBorderColour}`,
    padding: "10px 16px 24px",
    marginBottom: 28
} : {
    background: "#FFFFFF",
    boxShadow: "0 2px 17px 0 rgba(40,49,61,0.11)",
    borderRadius: 4,
    padding: 14,
    marginBottom: 28
};

const DesktopHome = ({}) => {
    const { companyPublicId, restrictedStocksAreStale } = useSelector(({userCompanyPublicId, restrictedStocksAreStale}) => ({companyPublicId: userCompanyPublicId, restrictedStocksAreStale}));
    return (
        <div style={{maxWidth: 1024, fontSize: environment.TIM2 ? "14px" : null}}>
            <StaleRestrictedStocksWarning restrictedStocksAreStale={restrictedStocksAreStale}
                                          companyPublicId={companyPublicId}/>
            <div style={sectionStyle}>
                <h2 className="section-heading">Send Idea</h2>
                <IdeaDistribution/>
            </div>
            <div style={sectionStyle}>
                <h2 className="section-heading">Open Ideas</h2>
                <IdeaManagement/>
            </div>
        </div>
    );
};

export default DesktopHome;
