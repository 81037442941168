import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import * as consts from "../../common/constants";
import ButtonGroup from "./../../common/components/ButtonGroup.js";

const TimeHorizon = (props) => (
    <div className={classNames("pure-control-group", props.requiredClass)} >
        <label>Time Horizon</label>
        <ButtonGroup className="x-time-horizon-group"
                     onChange={props.onChange}
                     value={props.value}
                     buttons={[
                         { label:"2d", value: consts.TIME_HORIZON_2D, className: "idea-attribute__input" },
                         { label:"1w", value: consts.TIME_HORIZON_1W, className: "idea-attribute__input" },
                         { label:"1m", value: consts.TIME_HORIZON_1M, className: "idea-attribute__input" },
                         { label:"3m", value: consts.TIME_HORIZON_3M, className: "idea-attribute__input" },
                         { label:"6m", value: consts.TIME_HORIZON_6M, className: "idea-attribute__input" },
                         { label:"1y+", value: consts.TIME_HORIZON_1YPLUS, className: "idea-attribute__input" }
                     ]} />
    </div>
);
TimeHorizon.propTypes = {
    onChange: PropTypes.func,
    requiredClass: PropTypes.string,
    value: PropTypes.string
};

export default TimeHorizon;

