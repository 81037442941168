import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const SubmitButton = ({className, onSubmit, disabled, children}) => (
    <button className={classNames("pure-button pure-button-primary", className)}
            onClick={onSubmit}
            disabled={disabled}
            type="button"
            style={{padding: "0.5em 1em"}}>{children}</button>
);

SubmitButton.propTypes = {
    className: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default SubmitButton;
