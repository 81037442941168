import validate from "validate.js";

export default function (value) {
    if (value === null || value === undefined || value.trim() === "") {
        return "";
    }

    if (value.length > 255) {
        return "invalid";
    }

    const result = validate({website: value.trim()}, {
        website: {
            url: {
                schemes: ["http", "https"]
            }
        }
    });

    if (!result)
        return "";

    return "invalid";
}
