import * as R from "ramda";
import ajax, {isHttpError, isNetworkError} from "../../authenticationSafeAjax";
import {validationFormParamsFor} from "./../../TIMRequestParams.js";

const UNFIXABLE_ERRORS = [
    "No valid investment amount for this portfolio", // TODO: Wrong spot for this! It is generated downstream
    "Max # long ideas per company Hard limit: 0",
    "Max # short ideas per company Hard limit: 0",
    "Max market cap Hard limit:",
    "Min market cap Hard limit:",
    "Distribution for this stock is not allowed",
    "Violates Portfolio Rules – Please Contact Support",
    "Disallowed industry",
    "Disallowed exchange",
    "Disallowed instrument class",
    "Max % of ADV Hard limit:"
];

function toValidationError(message, {uniqueId}) {
    const text = message.rule + " " + message.limit;
    return {
        forID: uniqueId,
        message: text,
        fixable: !UNFIXABLE_ERRORS.find(unfixable => text.startsWith(unfixable))
    };
}

function failuresAndInvestmentsFrom(src) {
    return { failures: R.flatten(
        src.filter(result => result.type === "rules")
           .map(ruleResult => {
               return ruleResult.messages.filter(message => message.status === "error")
                                .map(message => toValidationError(message, ruleResult));
           })
    ), portfolioInvestments: R.flatten(src.filter(s => s.portfolioInvestments).map(s => s.portfolioInvestments))};
}

let pendingValidationRequest = null;

export default function (portfolios, validationAttributes, callback, errorCallback) {
    if (pendingValidationRequest != null) {
        pendingValidationRequest.abort();
    }

    pendingValidationRequest = ajax({
            body: validationFormParamsFor(portfolios, validationAttributes), // TODO: URL encoding
            url: "/TradeIdeasMonitor/monitor?customAction=linkedIdeaGroupRuleValidationAction",
            contentType: "application/x-www-form-urlencoded",
            error: () => {
                errorCallback();
            },
            complete: () => {
                pendingValidationRequest = null;
            }
        },
        (statusCode, body) => {
            if (!isHttpError(statusCode) && !isNetworkError(statusCode)) {
                callback(failuresAndInvestmentsFrom(body));
            }
        });
}
