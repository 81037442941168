import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {DIRECTION_LONG, DIRECTION_SHORT} from "../constants";
import ButtonGroup from "./ButtonGroup";

export const Direction = (props) => (
    <div className={classNames("pure-control-group", props.requiredClass)} id="directionGroupDiv">
        <label>Direction</label>
        <ButtonGroup className="x-direction-group"
                     onChange={props.onChange}
                     value={props.value}
                     buttons={[
                         { label:"Long", value: DIRECTION_LONG, className: "idea-attribute__input" },
                         { label:"Short", value: DIRECTION_SHORT, className: "idea-attribute__input" }
                     ]}/>
    </div>
);
Direction.propTypes = {
    value: PropTypes.string,
    requiredClass: PropTypes.string,
    onChange: PropTypes.func
};

export default Direction;