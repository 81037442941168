import nanoajax from "nanoajax";

function emptyCallback() {}

export default function microajax(options) {
    const nanoOptions = {
        url: options.url,
        headers: ({...options.headers}),
        method: options.method
    };
    if (!nanoOptions.headers['Accept']) {
        nanoOptions.headers['Accept'] = 'application/json';
    }
    if (options.data) {
        if (options.contentType) {
            nanoOptions.headers['Content-Type'] = options.contentType;
            nanoOptions.body = options.data;
        }
        else {
            nanoOptions.headers['Content-Type'] = 'application/json';
            nanoOptions.body = JSON.stringify(options.data);
        }
    }
    nanoOptions.headers["X-Requested-With"] = "XMLHttpRequest";
    const onSuccess = options.success || emptyCallback;
    const onError = options.error || emptyCallback;
    const onComplete = options.complete || emptyCallback;

    return nanoajax.ajax(nanoOptions, (code, responseText, xhr) => {
        if (code >= 200 && code < 300) {
            if (code === 200) {
                const contentType = xhr.getResponseHeader("Content-Type");
                const data = (contentType && contentType.indexOf("application/json") !== -1) ? JSON.parse(responseText) : responseText;
                onSuccess(data, code, xhr);
            }
            else {
                onSuccess(null, code, xhr);
            }
        }
        else {
            onError(xhr, code, null);
        }
        onComplete(xhr, code);
    });
}
