import React from "react";
import PropTypes from "prop-types";

export const portfolio = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isIdeaCreationRequired: PropTypes.bool.isRequired
});

export const ruleValidationError = PropTypes.shape({
    forID: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    fixable: PropTypes.bool.isRequired
});

export const classifiedPortfolio = PropTypes.shape({
    classification: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(ruleValidationError).isRequired,
    portfolio: portfolio.isRequired
});

export const classifiedPortfolios = PropTypes.arrayOf(classifiedPortfolio);

export const submissionStatus = PropTypes.shape({
    success: PropTypes.arrayOf(PropTypes.string).isRequired,
    errors: PropTypes.arrayOf(PropTypes.string).isRequired
});
